import { CanvasContext } from "contexts/canvasContext";
import { useData } from "contexts/dataContext";
import { useContext } from "react";
import { updateSimulation } from "../simulation";
import { EDeleteType, iData, NiamSystemItem } from "../types";
import { getShown } from "../utils";
import { deleteD3Action } from "./authz_action";
import { deleteD3Claim } from "./authz_claim";
import { deleteD3Holder } from "./authz_holder";
import { deleteD3Issuer } from "./authz_issuer";
import { deleteD3Resource } from "./authz_resource";
import { deleteD3Subject } from "./authz_subject";
import { deleteD3VC } from "./authz_vc";
import { deleteD3CustomMutation } from "./customMutation";
import { deleteD3Enum } from "./enum";
import { deleteD3Implement } from "./implement";
import { deleteD3Interface } from "./interface";
import { deleteD3NodeRelationship } from "./nodeRelationship";
import { deleteD3Object } from "./object";
import { deleteD3Paths } from "./paths";
import { deleteD3Relationship } from "./relationship";
import { deleteD3Script } from "./script";
import { deleteD3SimplePath, deleteD3SimplePathCreate, deleteD3SimplePathDelete, deleteD3SimplePathRead, deleteD3SimplePathUpdate } from "./simplePath";
import { deleteD3Tag } from "./tag";

export const useDeleteD3Items = (): (({ type, id }: { type: EDeleteType; id: string; }) => void) => {
    const { d3Data, data, setData } = useContext(CanvasContext);
    const { reload } = useData();

    return ({ type, id }) => {
        let newData = {} as iData;

        switch (type) {
            case EDeleteType.OBJECT:
                newData = deleteD3Object({
                    d3Data,
                    data,
                    setData,
                    objectId: id
                });
                break;

            case EDeleteType.INTERFACE:
                newData = deleteD3Interface({
                    d3Data,
                    data,
                    setData,
                    interfaceId: id
                });
                break;

            case EDeleteType.SCRIPT:
                newData = deleteD3Script({
                    d3Data,
                    data,
                    setData,
                    scriptId: id
                });
                break;

            case EDeleteType.CUSTOM_MUTATION:
                newData = deleteD3CustomMutation({
                    d3Data,
                    data,
                    setData,
                    customMutationId: id
                });
                break;

            case EDeleteType.NODE_RELATIONSHIP:
                newData = deleteD3NodeRelationship({
                    d3Data,
                    data,
                    setData,
                    nodeRelationshipId: id
                });
                break;

            case EDeleteType.RELATIONSHIP:
                newData = deleteD3Relationship({
                    d3Data,
                    data,
                    setData,
                    relId: id
                });
                break;

            case EDeleteType.IMPLEMENT:
                newData = deleteD3Implement({
                    d3Data,
                    data,
                    setData,
                    implementId: id
                });
                break;

            case EDeleteType.PATHS:
                newData = deleteD3Paths({
                    d3Data,
                    data,
                    setData,
                    pathId: id
                });
                break;

            case EDeleteType.TAG:
                newData = deleteD3Tag({
                    d3Data,
                    data,
                    setData,
                    tagId: id
                });
                break;

            case EDeleteType.ENUM:
                newData = deleteD3Enum({
                    d3Data,
                    data,
                    setData,
                    enumId: id
                });
                break;

            case EDeleteType.SIMPLE_PATH:
                newData = deleteD3SimplePath({
                    d3Data,
                    data,
                    setData,
                    relatedTo: id
                });
                break;

            case EDeleteType.SIMPLE_PATH_CREATE:
                newData = deleteD3SimplePathCreate({
                    d3Data,
                    data,
                    setData,
                    relatedTo: id
                });
                break;

            case EDeleteType.SIMPLE_PATH_READ:
                newData = deleteD3SimplePathRead({
                    d3Data,
                    data,
                    setData,
                    relatedTo: id
                });
                break;

            case EDeleteType.SIMPLE_PATH_UPDATE:
                newData = deleteD3SimplePathUpdate({
                    d3Data,
                    data,
                    setData,
                    relatedTo: id
                });
                break;

            case EDeleteType.SIMPLE_PATH_DELETE:
                newData = deleteD3SimplePathDelete({
                    d3Data,
                    data,
                    setData,
                    relatedTo: id
                });
                break;

            case EDeleteType.AUTHZ_SUBJECT:
                newData = deleteD3Subject({
                    d3Data,
                    data,
                    setData,
                    relatedTo: id
                });
                break;

            case EDeleteType.AUTHZ_RESOURCE:
                newData = deleteD3Resource({
                    d3Data,
                    data,
                    setData,
                    relatedTo: id
                });
                break;

            case EDeleteType.AUTHZ_ACTION:
                newData = deleteD3Action({
                    d3Data,
                    data,
                    setData,
                    relatedTo: id
                });
                break;

            case EDeleteType.AUTHZ_VC:
                newData = deleteD3VC({
                    d3Data,
                    data,
                    setData,
                    relatedTo: id
                });
                break;

            case EDeleteType.AUTHZ_HOLDER:
                newData = deleteD3Holder({
                    d3Data,
                    data,
                    setData,
                    relatedTo: id
                });
                break;

            case EDeleteType.AUTHZ_ISSUER:
                newData = deleteD3Issuer({
                    d3Data,
                    data,
                    setData,
                    relatedTo: id
                });
                break;

            case EDeleteType.AUTHZ_CLAIM:
                newData = deleteD3Claim({
                    d3Data,
                    data,
                    setData,
                    relatedTo: id
                });
                break;
        }

        const orbitShown = getShown<NiamSystemItem>([
            ...newData.objects,
            ...newData.interfaces,
            ...newData.scripts,
            ...newData.customMutations,
            ...newData.tags,
            ...newData.enums,
            ...newData.nodeRelationships
        ]);

        const linkShown = getShown([...newData.implementLinks, ...newData.relLinks, ...newData.paths]).reverse();

        d3Data.g
            .selectAll(".orbit")
            .data(orbitShown, (node) => (node as NiamSystemItem)._id)
            .exit()
            .attr("fill-opacity", 1)
            .transition()
            .duration(500)
            .attr("fill-opacity", 0)
            .remove();

        if (type === "relationship") {
            const p = d3Data.g.select(`#relationshipID_${id}`)

            if (p && p.node() && p.attr('class').includes("petals")) {
                // d3Data.g.selectAll(".petals").remove()
            }

            reload()
        }

        d3Data.g
            .selectAll(".link")
            .data(linkShown, (node) => (node as NiamSystemItem)._id)
            .exit()
            .remove();

        updateSimulation(d3Data.simulation, orbitShown, linkShown);
    };
};
