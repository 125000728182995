/* eslint-disable max-lines */
/* eslint-disable no-loop-func */
import { toastError, toastSuccess, toastWarning } from "@3edges/utils/dist/toastify";
import { isEmpty, isNotEmpty, randomKey } from "@3edges/utils/dist/utils";
import { useMutation } from "@apollo/client";
import { COLORS } from "assets/defaultVariables";
import {
    RP_ADD_FIELD_TO_NODE_RELATIONSHIP,
    RP_DELETE_CUSTOM_MUTATION,
    RP_DELETE_ENUM,
    RP_DELETE_INTERFACE,
    RP_DELETE_NODE_RELATIONSHIP,
    RP_DELETE_OBJECT,
    RP_DELETE_SCRIPT,
    RP_DELETE_TAG
} from "components/RightPanel/gql";
import { useData } from "contexts/dataContext";
import { ContextMenuContext, IMenuOption } from "contexts/rightClickContext";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import {
    addNewNodeRelationship as AddNewNodeRelationship,
    d3AddPropertyToScript as D3AddPropertyToScript, NiamFieldTypeEnum,
    propertyTypes,
    rPAddFieldToInterface, rPAddFieldToNodeRelationship, rPAddFieldToObject,
    rPDeleteCustomMutation,
    rPDeleteEnum,
    rPDeleteNodeRelationship,
    rPDeleteScript,
    rPDeleteTag,
    rPInterfaceDelete,
    rPObjectDelete
} from "types/operation-result-types";
import { setCurve } from "utils";
import { useCanvasContext } from "../../../contexts/canvasContext";
import { checkIfAlreadyExistsVCs } from "../LeftPanelDragHandlersHook/LeftPanelDragHandlersHook";
import { useAddD3Items } from "../addD3Items/useAddD3Items";
import { setDefaultName } from "../d3UseEffects/draw";
import { useDeleteD3Items } from "../deleteD3Items/useDeleteD3Items";
import { EDeleteType, MenuOptionsType, NodeTypename, OrbitItem, RelationshipType } from "../types";
import {
    useAddNewAction, useAddNewActionRelationship,
    useAddNewClaim, useAddNewHolder, useAddNewIssuer,
    useAddNewPath, useAddNewPathCreate, useAddNewPathDelete, useAddNewPathRead, useAddNewPathUpdate, useAddNewResource, useAddNewSubject, useAddNewVC,
    useAddNewVCRelationship
} from "./OnPageRightClick";
import {
    D3_ADD_NODE_RELATIONSHIP,
    D3_ADD_PROPERTY_TO_INTERFACE,
    D3_ADD_PROPERTY_TO_OBJECT,
    D3_ADD_PROPERTY_TO_SCRIPT,
    D3_IMPLEMENT_INTERFACE,
    DELETE_PATH_OBJECT_TO_CUSTOM_MUTATION, REMOVE_ACTION, REMOVE_ACTION_RELATIONSHIP, REMOVE_CLAIM, REMOVE_HOLDER, REMOVE_ISSUER,
    REMOVE_RESOURCE,
    REMOVE_SIMPLE_PATH,
    REMOVE_SIMPLE_PATH_CREATE,
    REMOVE_SIMPLE_PATH_DELETE,
    REMOVE_SIMPLE_PATH_READ,
    REMOVE_SIMPLE_PATH_UPDATE,
    REMOVE_SUBJECT,
    REMOVE_VC,
    REMOVE_VC_RELATIONSHIP
} from "./gql";

export const useOnOrbitsRightClick = (isShowDashboard: boolean): void => {
    const contextMenuState = useContext(ContextMenuContext);
    const { enters, data, d3Data } = useCanvasContext();
    const { server, setItemSelected } = useData();
    const onDelete = useDeleteD3Items();

    useEffect(() => {
        setContextMenuNotVisible();
    }, [isShowDashboard])

    const addD3Item = useAddD3Items();
    const addNewPath = useAddNewPath();
    const addNewPathCreate = useAddNewPathCreate();
    const addNewPathRead = useAddNewPathRead();
    const addNewPathUpdate = useAddNewPathUpdate();
    const addNewPathDelete = useAddNewPathDelete();
    const addNewResource = useAddNewResource();
    const addNewAction = useAddNewAction();
    const addNewVC = useAddNewVC();
    const addNewHolder = useAddNewHolder();
    const addNewIssuer = useAddNewIssuer();
    const addNewClaim = useAddNewClaim();
    const addNewSubject = useAddNewSubject();
    const addNewActionRelationship = useAddNewActionRelationship();
    const addNewVCRelationship = useAddNewVCRelationship();

    const [addNewPropertyToObjectMutation] = useMutation<rPAddFieldToObject>(D3_ADD_PROPERTY_TO_OBJECT);
    const { t } = useTranslation();
    const [implementInterface] = useMutation(D3_IMPLEMENT_INTERFACE);
    const [addNewPropertyToScriptMutation] = useMutation<D3AddPropertyToScript>(D3_ADD_PROPERTY_TO_SCRIPT);
    const [addNewNodeRelationship] = useMutation<AddNewNodeRelationship>(D3_ADD_NODE_RELATIONSHIP);
    const [addNewPropertyToIntefaceMutation] = useMutation<rPAddFieldToInterface>(D3_ADD_PROPERTY_TO_INTERFACE);
    const [deleteObject] = useMutation<rPObjectDelete>(RP_DELETE_OBJECT);
    const [deleteInterface] = useMutation<rPInterfaceDelete>(RP_DELETE_INTERFACE);
    const [deleteNodeRelationship] = useMutation<rPDeleteNodeRelationship>(RP_DELETE_NODE_RELATIONSHIP);
    const [deleteScript] = useMutation<rPDeleteScript>(RP_DELETE_SCRIPT);
    const [deleteCustomMutation] = useMutation<rPDeleteCustomMutation>(RP_DELETE_CUSTOM_MUTATION);
    const [deleteTag] = useMutation<rPDeleteTag>(RP_DELETE_TAG);
    const [deleteEnum] = useMutation<rPDeleteEnum>(RP_DELETE_ENUM);
    const [deleteSimplePath] = useMutation(REMOVE_SIMPLE_PATH);
    const [deleteSimplePathCreate] = useMutation(REMOVE_SIMPLE_PATH_CREATE);
    const [deleteSimplePathRead] = useMutation(REMOVE_SIMPLE_PATH_READ);
    const [deleteSimplePathUpdate] = useMutation(REMOVE_SIMPLE_PATH_UPDATE);
    const [deleteSimplePathDelete] = useMutation(REMOVE_SIMPLE_PATH_DELETE);
    const [deleteSubject] = useMutation(REMOVE_SUBJECT);
    const [deleteResource] = useMutation(REMOVE_RESOURCE);
    const [deleteAction] = useMutation(REMOVE_ACTION);
    const [deleteVC] = useMutation(REMOVE_VC);
    const [deleteHolder] = useMutation(REMOVE_HOLDER);
    const [deleteIssuer] = useMutation(REMOVE_ISSUER);
    const [deleteClaim] = useMutation(REMOVE_CLAIM);
    const [deletePaths] = useMutation(DELETE_PATH_OBJECT_TO_CUSTOM_MUTATION);
    const [deleteActionRelationship] = useMutation(REMOVE_ACTION_RELATIONSHIP);
    const [deleteVCRelationship] = useMutation(REMOVE_VC_RELATIONSHIP);

    const [addFieldToNodeRelationship] = useMutation<rPAddFieldToNodeRelationship>(RP_ADD_FIELD_TO_NODE_RELATIONSHIP);

    const setContextMenuNotVisible = (): void => {
        contextMenuState.setContextMenuState({
            visibility: false
        });
    };

    const showConfirmDeletionPopup = ({
        title,
        onConfirm,
        _id,
        relatedTo
    }: {
        title: string;
        onConfirm: (orbitID: string, relatedTo?: string) => void;
        _id: string;
        relatedTo?: string;
    }): void => {
        void Swal.fire({
            title,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: COLORS.GREEN,
            cancelButtonColor: COLORS.RED,
            confirmButtonText: t("rightClick:confirm.yes"),
            cancelButtonText: t("rightClick:confirm.no")
        }).then((result) => {
            setContextMenuNotVisible();

            if (result.isConfirmed) {
                onConfirm(_id, relatedTo);
            }
        });
    };

    const showSuccessDeletePopup = (): void => {
        toastSuccess(t("rightClick:success.delete"));
    };

    const showFailDeletePopup = (): void => {
        toastError(t("rightClick:fail.delete"));
    };

    const deleteObjectHandler = (orbitID: string): void => {
        void deleteObject({
            variables: { ObjectID: orbitID }
        }).then((res) => {
            if (res.data.deleteNiamObject) {
                onDelete({
                    id: orbitID,
                    type: EDeleteType.OBJECT
                });
                showSuccessDeletePopup();
            } else {
                showFailDeletePopup();
            }
        });
    };

    const deleteInterfaceHandler = (orbitID: string): void => {
        void deleteInterface({
            variables: { interfaceID: orbitID }
        }).then((res) => {
            if (res.data.deleteNiamInterface) {
                onDelete({
                    id: orbitID,
                    type: EDeleteType.INTERFACE
                });
                showSuccessDeletePopup();
            } else {
                showFailDeletePopup();
            }
        });
    };

    const deleteNodeRelationshipHandler = (orbitID: string): void => {
        void deleteNodeRelationship({
            variables: { nodeRelationshipID: orbitID }
        }).then((res) => {
            if (res.data.deleteNiamNodeRelationship) {
                onDelete({
                    id: orbitID,
                    type: EDeleteType.NODE_RELATIONSHIP
                });
                showSuccessDeletePopup();
            } else {
                showFailDeletePopup();
            }
        });
    };

    const deleteScriptHandler = (orbitID: string): void => {
        void deleteScript({
            variables: { scriptID: orbitID }
        }).then((res) => {
            if (res.data.deleteNiamScript) {
                onDelete({
                    id: orbitID,
                    type: EDeleteType.SCRIPT
                });
                showSuccessDeletePopup();
            } else {
                showFailDeletePopup();
            }
        });
    };

    const deleteCustomMutationHandler = (orbitID: string): void => {
        void deleteCustomMutation({
            variables: { customMutationID: orbitID }
        }).then((res) => {
            if (res.data.deleteNiamCustomMutation) {
                onDelete({
                    id: orbitID,
                    type: EDeleteType.CUSTOM_MUTATION
                });
                showSuccessDeletePopup();
            } else {
                showFailDeletePopup();
            }
        });
    };

    const deleteTagHandler = (orbitID: string): void => {
        void deleteTag({
            variables: { tagID: orbitID }
        }).then((res) => {
            if (res.data.deleteNiamTag) {
                onDelete({
                    id: orbitID,
                    type: EDeleteType.TAG
                });
                showSuccessDeletePopup();
            } else {
                showFailDeletePopup();
            }
        });
    };

    const deleteEnumHandler = (orbitID: string): void => {
        void deleteEnum({
            variables: { _id: orbitID }
        }).then((res) => {
            if (res.data.deleteNiamEnum) {
                onDelete({
                    id: orbitID,
                    type: EDeleteType.ENUM
                });
                showSuccessDeletePopup();
            } else {
                showFailDeletePopup();
            }
        });
    };

    const deleteAuthPaths = (orbitID: string): void => {
        void deletePaths({
            variables: { _id: orbitID }
        }).then((res) => {
            if (res.data.deletePathObjectToCustomMutation) {

                d3Data.g.select(`#relationshipID_${orbitID}`).remove();

                onDelete({
                    id: orbitID,
                    type: EDeleteType.PATHS
                });
            }
        });
    };

    const deleteAuthSimplePath = (orbitID: string, relatedTo: string): void => {
        void deleteSimplePath({
            variables: { _id: orbitID }
        }).then((res) => {
            if (res.data.removeSimplePathAuthZLabelFromRelationship) {
                const nr = data.nodeRelationships.find(nr => nr._id === relatedTo)

                if (nr) {
                    const selectedOrbit = d3Data.g.select(`#orbitID_${relatedTo}`)
                    selectedOrbit.select(`#authPath_${relatedTo}`).remove();

                    const currentLinkBeforeNR = d3Data.g.select(`#relationshipID_${nr.relation.relFrom._id}`)
                    currentLinkBeforeNR.select(`#simplePath_${nr.relation.relFrom._id}`).remove();

                    const currentLinkAfterNR = d3Data.g.select(`#relationshipID_${nr.relation.relTo._id}`)
                    currentLinkAfterNR.select(`#simplePath_${nr.relation.relTo._id}`).remove();


                    const currentLinkBeforeNRPath = d3Data.g.select(`#simplePath_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPath = d3Data.g.select(`#simplePath_${nr.relation.relTo._id}`)

                    const currentLinkBeforeNRPathCreate = d3Data.g.select(`#simplePathCreate_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathCreate = d3Data.g.select(`#simplePathCreate_${nr.relation.relTo._id}`)

                    const currentLinkBeforeNRPathRead = d3Data.g.select(`#simplePathRead_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathRead = d3Data.g.select(`#simplePathRead_${nr.relation.relTo._id}`)

                    const currentLinkBeforeNRPathUpdate = d3Data.g.select(`#simplePathUpdate_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathUpdate = d3Data.g.select(`#simplePathUpdate_${nr.relation.relTo._id}`)

                    const currentLinkBeforeNRPathDelete = d3Data.g.select(`#simplePathDelete_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathDelete = d3Data.g.select(`#simplePathDelete_${nr.relation.relTo._id}`)

                    const listLabelBefore = [];
                    const listLabelAfter = [];
                    if(currentLinkBeforeNRPath.node()){
                        listLabelBefore.push(currentLinkBeforeNRPath);
                    }
                    if(currentLinkBeforeNRPathCreate.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathCreate);
                    }
                    if(currentLinkBeforeNRPathRead.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathRead);
                    }
                    if(currentLinkBeforeNRPathUpdate.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathUpdate);
                    }
                    if(currentLinkBeforeNRPathDelete.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathDelete);
                    }

                    if(currentLinkAfterNRPath.node()){
                        listLabelAfter.push(currentLinkAfterNRPath);
                    }
                    if(currentLinkAfterNRPathCreate.node()){
                        listLabelAfter.push(currentLinkAfterNRPathCreate);
                    }
                    if(currentLinkAfterNRPathRead.node()){
                        listLabelAfter.push(currentLinkAfterNRPathRead);
                    }
                    if(currentLinkAfterNRPathUpdate.node()){
                        listLabelAfter.push(currentLinkAfterNRPathUpdate);
                    }
                    if(currentLinkAfterNRPathDelete.node()){
                        listLabelAfter.push(currentLinkAfterNRPathDelete);
                    }

                    if(listLabelBefore.length === 0 || listLabelAfter.length === 0){
                        selectedOrbit.classed("setColorIsPath", false);
                        currentLinkBeforeNR.classed("setColorPath", false);
                        currentLinkAfterNR.classed("setColorPath", false);
                    }

                    switch (listLabelBefore.length) {
                        case 1: {
                            listLabelBefore[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabelBefore[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabelBefore[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabelBefore[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabelBefore[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabelBefore[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabelBefore[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabelBefore[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabelBefore[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabelBefore[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabelBefore[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabelBefore[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabelBefore[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabelBefore[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabelBefore[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        // No default
                        }

                        switch (listLabelAfter.length) {
                        case 1: {
                            listLabelAfter[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabelAfter[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabelAfter[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabelAfter[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabelAfter[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabelAfter[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabelAfter[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabelAfter[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabelAfter[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabelAfter[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabelAfter[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabelAfter[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabelAfter[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabelAfter[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabelAfter[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        // No default
                        }

                } else {
                    const currentLink = d3Data.g.select(`#relationshipID_${relatedTo}`)
                    currentLink.select(`#simplePath_${relatedTo}`).remove();

                    const currentAuthRelAction = d3Data.g.select(`#actionRelationship_${relatedTo}`)
                    const currentAuthRelVC = d3Data.g.select(`#vcRelationship_${relatedTo}`)

                    const currentAuthSimplePathCreate = d3Data.g.select(`#simplePathCreate_${relatedTo}`)
                    const currentAuthSimplePathUpdate = d3Data.g.select(`#simplePathRead_${relatedTo}`)
                    const currentAuthSimplePathRead = d3Data.g.select(`#simplePathUpdate_${relatedTo}`)
                    const currentAuthSimplePathDelete = d3Data.g.select(`#simplePathDelete_${relatedTo}`)

                    const listLabel = [];

                    if (currentAuthSimplePathCreate.node()) {
                        listLabel.push(currentAuthSimplePathCreate)
                    }

                    if (currentAuthSimplePathUpdate.node()) {
                        listLabel.push(currentAuthSimplePathUpdate)
                    }

                    if (currentAuthSimplePathRead.node()) {
                        listLabel.push(currentAuthSimplePathRead)
                    }

                    if (currentAuthSimplePathDelete.node()) {
                        listLabel.push(currentAuthSimplePathDelete)
                    }

                    if (currentAuthRelAction.node()) {
                        listLabel.push(currentAuthRelAction)
                    }

                    if (currentAuthRelVC.node()) {
                        listLabel.push(currentAuthRelVC)
                    }

                    switch (listLabel.length) {
                        case 0: {
                            currentLink.classed("setColorLinePetal", false);
                            currentLink.classed("setColorPath", false);

                        break;
                        }
                        case 1: {
                            listLabel[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabel[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabel[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabel[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabel[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabel[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabel[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabel[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabel[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabel[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabel[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabel[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabel[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabel[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabel[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        case 6: {
                            listLabel[5].attr("startOffset", "84%").attr("font-size", "10px");
                            listLabel[4].attr("startOffset", "70%").attr("font-size", "10px");
                            listLabel[3].attr("startOffset", "56%").attr("font-size", "10px");
                            listLabel[2].attr("startOffset", "42%").attr("font-size", "10px");
                            listLabel[1].attr("startOffset", "28%").attr("font-size", "10px");
                            listLabel[0].attr("startOffset", "14%").attr("font-size", "10px");

                        break;
                        }
                        case 7: {
                            listLabel[6].attr("startOffset", "86.5%").attr("font-size", "9px");
                            listLabel[5].attr("startOffset", "75%").attr("font-size", "9px");
                            listLabel[4].attr("startOffset", "62.2%").attr("font-size", "9px");
                            listLabel[3].attr("startOffset", "50%").attr("font-size", "9px");
                            listLabel[2].attr("startOffset", "37.5%").attr("font-size", "9px");
                            listLabel[1].attr("startOffset", "25%").attr("font-size", "9px");
                            listLabel[0].attr("startOffset", "12.5%").attr("font-size", "9px");

                        break;
                        }
                        // No default
                        }
                }

                onDelete({
                    id: relatedTo,
                    type: EDeleteType.SIMPLE_PATH
                });
            }
        });
    };

    const deleteAuthSimplePathCreate = (orbitID: string, relatedTo: string): void => {
        void deleteSimplePathCreate({
            variables: { _id: orbitID }
        }).then((res) => {
            if (res.data.removeSimplePathCreateAuthZLabelFromRelationship) {
                const nr = data.nodeRelationships.find(nr => nr._id === relatedTo)

                if (nr) {
                    const selectedOrbit = d3Data.g.select(`#orbitID_${relatedTo}`)
                    selectedOrbit.select(`#authPathCreate_${relatedTo}`).remove();

                    const currentLinkBeforeNR = d3Data.g.select(`#relationshipID_${nr.relation.relFrom._id}`)
                    currentLinkBeforeNR.select(`#simplePathCreate_${nr.relation.relFrom._id}`).remove();

                    const currentLinkAfterNR = d3Data.g.select(`#relationshipID_${nr.relation.relTo._id}`)
                    currentLinkAfterNR.select(`#simplePathCreate_${nr.relation.relTo._id}`).remove();

                    const currentLinkBeforeNRPath = d3Data.g.select(`#simplePath_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPath = d3Data.g.select(`#simplePath_${nr.relation.relTo._id}`)
                    const currentLinkBeforeNRPathCreate = d3Data.g.select(`#simplePathCreate_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathCreate = d3Data.g.select(`#simplePathCreate_${nr.relation.relTo._id}`)
                    const currentLinkBeforeNRPathRead = d3Data.g.select(`#simplePathRead_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathRead = d3Data.g.select(`#simplePathRead_${nr.relation.relTo._id}`)
                    const currentLinkBeforeNRPathUpdate = d3Data.g.select(`#simplePathUpdate_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathUpdate = d3Data.g.select(`#simplePathUpdate_${nr.relation.relTo._id}`)
                    const currentLinkBeforeNRPathDelete = d3Data.g.select(`#simplePathDelete_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathDelete = d3Data.g.select(`#simplePathDelete_${nr.relation.relTo._id}`)

                    const listLabelBefore = [];
                    const listLabelAfter = [];
                    if(currentLinkBeforeNRPath.node()){
                        listLabelBefore.push(currentLinkBeforeNRPath);
                    }
                    if(currentLinkBeforeNRPathCreate.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathCreate);
                    }
                    if(currentLinkBeforeNRPathRead.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathRead);
                    }
                    if(currentLinkBeforeNRPathUpdate.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathUpdate);
                    }
                    if(currentLinkBeforeNRPathDelete.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathDelete);
                    }
                    if(currentLinkAfterNRPath.node()){
                        listLabelAfter.push(currentLinkAfterNRPath);
                    }
                    if(currentLinkAfterNRPathCreate.node()){
                        listLabelAfter.push(currentLinkAfterNRPathCreate);
                    }
                    if(currentLinkAfterNRPathRead.node()){
                        listLabelAfter.push(currentLinkAfterNRPathRead);
                    }
                    if(currentLinkAfterNRPathUpdate.node()){
                        listLabelAfter.push(currentLinkAfterNRPathUpdate);
                    }
                    if(currentLinkAfterNRPathDelete.node()){
                        listLabelAfter.push(currentLinkAfterNRPathDelete);
                    }
                    if(listLabelBefore.length === 0 || listLabelAfter.length === 0){
                        selectedOrbit.classed("setColorIsPath", false);
                        currentLinkBeforeNR.classed("setColorPath", false);
                        currentLinkAfterNR.classed("setColorPath", false);
                    }

                    switch (listLabelBefore.length) {
                        case 1: {
                            listLabelBefore[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabelBefore[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabelBefore[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabelBefore[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabelBefore[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabelBefore[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabelBefore[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabelBefore[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabelBefore[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabelBefore[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabelBefore[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabelBefore[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabelBefore[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabelBefore[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabelBefore[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        // No default
                        }

                        switch (listLabelAfter.length) {
                        case 1: {
                            listLabelAfter[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabelAfter[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabelAfter[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabelAfter[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabelAfter[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabelAfter[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabelAfter[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabelAfter[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabelAfter[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabelAfter[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabelAfter[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabelAfter[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabelAfter[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabelAfter[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabelAfter[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        // No default
                        }

                } else {
                    const currentLink = d3Data.g.select(`#relationshipID_${relatedTo}`)
                    currentLink.select(`#simplePathCreate_${relatedTo}`).remove();

                    const currentAuthRelAction = d3Data.g.select(`#actionRelationship_${relatedTo}`)
                    const currentAuthRelVC = d3Data.g.select(`#vcRelationship_${relatedTo}`)
                    const currentAuthSimplePath = d3Data.g.select(`#simplePath_${relatedTo}`)
                    const currentAuthSimplePathUpdate = d3Data.g.select(`#simplePathRead_${relatedTo}`)
                    const currentAuthSimplePathRead = d3Data.g.select(`#simplePathUpdate_${relatedTo}`)
                    const currentAuthSimplePathDelete = d3Data.g.select(`#simplePathDelete_${relatedTo}`)

                    const listLabel = [];

                    if (currentAuthSimplePath.node()) {
                        listLabel.push(currentAuthSimplePath)
                    }

                    if (currentAuthSimplePathUpdate.node()) {
                        listLabel.push(currentAuthSimplePathUpdate)
                    }

                    if (currentAuthSimplePathRead.node()) {
                        listLabel.push(currentAuthSimplePathRead)
                    }

                    if (currentAuthSimplePathDelete.node()) {
                        listLabel.push(currentAuthSimplePathDelete)
                    }

                    if (currentAuthRelAction.node()) {
                        listLabel.push(currentAuthRelAction)
                    }

                    if (currentAuthRelVC.node()) {
                        listLabel.push(currentAuthRelVC)
                    }

                    switch (listLabel.length) {
                        case 0: {
                            currentLink.classed("setColorLinePetal", false);
                            currentLink.classed("setColorPath", false);

                        break;
                        }
                        case 1: {
                            listLabel[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabel[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabel[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabel[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabel[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabel[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabel[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabel[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabel[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabel[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabel[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabel[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabel[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabel[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabel[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        case 6: {
                            listLabel[5].attr("startOffset", "84%").attr("font-size", "10px");
                            listLabel[4].attr("startOffset", "70%").attr("font-size", "10px");
                            listLabel[3].attr("startOffset", "56%").attr("font-size", "10px");
                            listLabel[2].attr("startOffset", "42%").attr("font-size", "10px");
                            listLabel[1].attr("startOffset", "28%").attr("font-size", "10px");
                            listLabel[0].attr("startOffset", "14%").attr("font-size", "10px");

                        break;
                        }
                        case 7: {
                            listLabel[6].attr("startOffset", "86.5%").attr("font-size", "9px");
                            listLabel[5].attr("startOffset", "75%").attr("font-size", "9px");
                            listLabel[4].attr("startOffset", "62.2%").attr("font-size", "9px");
                            listLabel[3].attr("startOffset", "50%").attr("font-size", "9px");
                            listLabel[2].attr("startOffset", "37.5%").attr("font-size", "9px");
                            listLabel[1].attr("startOffset", "25%").attr("font-size", "9px");
                            listLabel[0].attr("startOffset", "12.5%").attr("font-size", "9px");

                        break;
                        }
                        // No default
                        }
                }

                onDelete({
                    id: relatedTo,
                    type: EDeleteType.SIMPLE_PATH_CREATE
                });
            }
        });
    };

    const deleteAuthSimplePathRead = (orbitID: string, relatedTo: string): void => {
        void deleteSimplePathRead({
            variables: { _id: orbitID }
        }).then((res) => {
            if (res.data.removeSimplePathReadAuthZLabelFromRelationship) {
                const nr = data.nodeRelationships.find(nr => nr._id === relatedTo)

                if (nr) {
                    const selectedOrbit = d3Data.g.select(`#orbitID_${relatedTo}`)
                    selectedOrbit.select(`#authPathRead_${relatedTo}`).remove();

                    const currentLinkBeforeNR = d3Data.g.select(`#relationshipID_${nr.relation.relFrom._id}`)
                    currentLinkBeforeNR.select(`#simplePathRead_${nr.relation.relFrom._id}`).remove();

                    const currentLinkAfterNR = d3Data.g.select(`#relationshipID_${nr.relation.relTo._id}`)
                    currentLinkAfterNR.select(`#simplePathRead_${nr.relation.relTo._id}`).remove();

                    const currentLinkBeforeNRPath = d3Data.g.select(`#simplePath_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPath = d3Data.g.select(`#simplePath_${nr.relation.relTo._id}`)

                    const currentLinkBeforeNRPathCreate = d3Data.g.select(`#simplePathCreate_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathCreate = d3Data.g.select(`#simplePathCreate_${nr.relation.relTo._id}`)

                    const currentLinkBeforeNRPathRead = d3Data.g.select(`#simplePathRead_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathRead = d3Data.g.select(`#simplePathRead_${nr.relation.relTo._id}`)

                    const currentLinkBeforeNRPathUpdate = d3Data.g.select(`#simplePathUpdate_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathUpdate = d3Data.g.select(`#simplePathUpdate_${nr.relation.relTo._id}`)

                    const currentLinkBeforeNRPathDelete = d3Data.g.select(`#simplePathDelete_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathDelete = d3Data.g.select(`#simplePathDelete_${nr.relation.relTo._id}`)

                    const listLabelBefore = [];
                    const listLabelAfter = [];
                    if(currentLinkBeforeNRPath.node()){
                        listLabelBefore.push(currentLinkBeforeNRPath);
                    }
                    if(currentLinkBeforeNRPathCreate.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathCreate);
                    }
                    if(currentLinkBeforeNRPathRead.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathRead);
                    }
                    if(currentLinkBeforeNRPathUpdate.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathUpdate);
                    }
                    if(currentLinkBeforeNRPathDelete.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathDelete);
                    }

                    if(currentLinkAfterNRPath.node()){
                        listLabelAfter.push(currentLinkAfterNRPath);
                    }
                    if(currentLinkAfterNRPathCreate.node()){
                        listLabelAfter.push(currentLinkAfterNRPathCreate);
                    }
                    if(currentLinkAfterNRPathRead.node()){
                        listLabelAfter.push(currentLinkAfterNRPathRead);
                    }
                    if(currentLinkAfterNRPathUpdate.node()){
                        listLabelAfter.push(currentLinkAfterNRPathUpdate);
                    }
                    if(currentLinkAfterNRPathDelete.node()){
                        listLabelAfter.push(currentLinkAfterNRPathDelete);
                    }

                    if(listLabelBefore.length === 0 || listLabelAfter.length === 0){
                        selectedOrbit.classed("setColorIsPath", false);
                        currentLinkBeforeNR.classed("setColorPath", false);
                        currentLinkAfterNR.classed("setColorPath", false);
                    }

                    switch (listLabelBefore.length) {
                        case 1: {
                            listLabelBefore[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabelBefore[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabelBefore[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabelBefore[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabelBefore[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabelBefore[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabelBefore[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabelBefore[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabelBefore[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabelBefore[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabelBefore[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabelBefore[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabelBefore[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabelBefore[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabelBefore[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        // No default
                    }

                        switch (listLabelAfter.length) {
                            case 1: {
                                listLabelAfter[0].attr("startOffset", "50%").attr("font-size", "16px");

                            break;
                            }
                            case 2: {
                                listLabelAfter[1].attr("startOffset", "66%").attr("font-size", "14px");
                                listLabelAfter[0].attr("startOffset", "33%").attr("font-size", "14px");

                            break;
                            }
                            case 3: {
                                listLabelAfter[2].attr("startOffset", "75%").attr("font-size", "13px");
                                listLabelAfter[1].attr("startOffset", "50%").attr("font-size", "13px");
                                listLabelAfter[0].attr("startOffset", "25%").attr("font-size", "13px");

                            break;
                            }
                            case 4: {
                                listLabelAfter[3].attr("startOffset", "80%").attr("font-size", "12px");
                                listLabelAfter[2].attr("startOffset", "60%").attr("font-size", "12px");
                                listLabelAfter[1].attr("startOffset", "40%").attr("font-size", "12px");
                                listLabelAfter[0].attr("startOffset", "20%").attr("font-size", "12px");

                            break;
                            }
                            case 5: {
                                listLabelAfter[4].attr("startOffset", "82%").attr("font-size", "11px");
                                listLabelAfter[3].attr("startOffset", "66%").attr("font-size", "11px");
                                listLabelAfter[2].attr("startOffset", "50%").attr("font-size", "11px");
                                listLabelAfter[1].attr("startOffset", "32%").attr("font-size", "11px");
                                listLabelAfter[0].attr("startOffset", "16%").attr("font-size", "11px");

                            break;
                            }
                        // No default
                        }

                } else {
                    const currentLink = d3Data.g.select(`#relationshipID_${relatedTo}`)
                    currentLink.select(`#simplePathRead_${relatedTo}`).remove();

                    const currentAuthRelAction = d3Data.g.select(`#actionRelationship_${relatedTo}`)
                    const currentAuthRelVC = d3Data.g.select(`#vcRelationship_${relatedTo}`)
                    const currentAuthSimplePath = d3Data.g.select(`#simplePath_${relatedTo}`)
                    const currentAuthSimplePathCreate = d3Data.g.select(`#simplePathCreate_${relatedTo}`)
                    const currentAuthSimplePathUpdate = d3Data.g.select(`#simplePathRead_${relatedTo}`)
                    const currentAuthSimplePathDelete = d3Data.g.select(`#simplePathDelete_${relatedTo}`)

                    const listLabel = [];

                    if (currentAuthSimplePath.node()) {
                        listLabel.push(currentAuthSimplePath)
                    }

                    if (currentAuthSimplePathCreate.node()) {
                        listLabel.push(currentAuthSimplePathCreate)
                    }

                    if (currentAuthSimplePathUpdate.node()) {
                        listLabel.push(currentAuthSimplePathUpdate)
                    }

                    if (currentAuthSimplePathDelete.node()) {
                        listLabel.push(currentAuthSimplePathDelete)
                    }

                    if (currentAuthRelAction.node()) {
                        listLabel.push(currentAuthRelAction)
                    }

                    if (currentAuthRelVC.node()) {
                        listLabel.push(currentAuthRelVC)
                    }

                    switch (listLabel.length) {
                        case 0: {
                            currentLink.classed("setColorLinePetal", false);
                            currentLink.classed("setColorPath", false);

                        break;
                        }
                        case 1: {
                            listLabel[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabel[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabel[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabel[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabel[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabel[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabel[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabel[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabel[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabel[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabel[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabel[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabel[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabel[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabel[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        case 6: {
                            listLabel[5].attr("startOffset", "84%").attr("font-size", "10px");
                            listLabel[4].attr("startOffset", "70%").attr("font-size", "10px");
                            listLabel[3].attr("startOffset", "56%").attr("font-size", "10px");
                            listLabel[2].attr("startOffset", "42%").attr("font-size", "10px");
                            listLabel[1].attr("startOffset", "28%").attr("font-size", "10px");
                            listLabel[0].attr("startOffset", "14%").attr("font-size", "10px");

                        break;
                        }
                        case 7: {
                            listLabel[6].attr("startOffset", "86.5%").attr("font-size", "9px");
                            listLabel[5].attr("startOffset", "75%").attr("font-size", "9px");
                            listLabel[4].attr("startOffset", "62.2%").attr("font-size", "9px");
                            listLabel[3].attr("startOffset", "50%").attr("font-size", "9px");
                            listLabel[2].attr("startOffset", "37.5%").attr("font-size", "9px");
                            listLabel[1].attr("startOffset", "25%").attr("font-size", "9px");
                            listLabel[0].attr("startOffset", "12.5%").attr("font-size", "9px");

                        break;
                        }
                        // No default
                        }
                }

                onDelete({
                    id: relatedTo,
                    type: EDeleteType.SIMPLE_PATH_READ
                });
            }
        });
    };

    const deleteAuthSimplePathUpdate = (orbitID: string, relatedTo: string): void => {
        void deleteSimplePathUpdate({
            variables: { _id: orbitID }
        }).then((res) => {
            if (res.data.removeSimplePathUpdateAuthZLabelFromRelationship) {
                const nr = data.nodeRelationships.find(nr => nr._id === relatedTo)

                if (nr) {
                    const selectedOrbit = d3Data.g.select(`#orbitID_${relatedTo}`)
                    selectedOrbit.select(`#authPathUpdate_${relatedTo}`).remove();

                    const currentLinkBeforeNR = d3Data.g.select(`#relationshipID_${nr.relation.relFrom._id}`)
                    currentLinkBeforeNR.select(`#simplePathUpdate_${nr.relation.relFrom._id}`).remove();

                    const currentLinkAfterNR = d3Data.g.select(`#relationshipID_${nr.relation.relTo._id}`)
                    currentLinkAfterNR.select(`#simplePathUpdate_${nr.relation.relTo._id}`).remove();
                    const currentLinkBeforeNRPath = d3Data.g.select(`#simplePath_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPath = d3Data.g.select(`#simplePath_${nr.relation.relTo._id}`)
                    const currentLinkBeforeNRPathCreate = d3Data.g.select(`#simplePathCreate_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathCreate = d3Data.g.select(`#simplePathCreate_${nr.relation.relTo._id}`)
                    const currentLinkBeforeNRPathRead = d3Data.g.select(`#simplePathRead_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathRead = d3Data.g.select(`#simplePathRead_${nr.relation.relTo._id}`)
                    const currentLinkBeforeNRPathUpdate = d3Data.g.select(`#simplePathUpdate_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathUpdate = d3Data.g.select(`#simplePathUpdate_${nr.relation.relTo._id}`)
                    const currentLinkBeforeNRPathDelete = d3Data.g.select(`#simplePathDelete_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathDelete = d3Data.g.select(`#simplePathDelete_${nr.relation.relTo._id}`)

                    const listLabelBefore = [];
                    const listLabelAfter = [];
                    if(currentLinkBeforeNRPath.node()){
                        listLabelBefore.push(currentLinkBeforeNRPath);
                    }
                    if(currentLinkBeforeNRPathCreate.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathCreate);
                    }
                    if(currentLinkBeforeNRPathRead.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathRead);
                    }
                    if(currentLinkBeforeNRPathUpdate.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathUpdate);
                    }
                    if(currentLinkBeforeNRPathDelete.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathDelete);
                    }
                    if(currentLinkAfterNRPath.node()){
                        listLabelAfter.push(currentLinkAfterNRPath);
                    }
                    if(currentLinkAfterNRPathCreate.node()){
                        listLabelAfter.push(currentLinkAfterNRPathCreate);
                    }
                    if(currentLinkAfterNRPathRead.node()){
                        listLabelAfter.push(currentLinkAfterNRPathRead);
                    }
                    if(currentLinkAfterNRPathUpdate.node()){
                        listLabelAfter.push(currentLinkAfterNRPathUpdate);
                    }
                    if(currentLinkAfterNRPathDelete.node()){
                        listLabelAfter.push(currentLinkAfterNRPathDelete);
                    }
                    if(listLabelBefore.length === 0 || listLabelAfter.length === 0){
                        selectedOrbit.classed("setColorIsPath", false);
                        currentLinkBeforeNR.classed("setColorPath", false);
                        currentLinkAfterNR.classed("setColorPath", false);
                    }

                    switch (listLabelBefore.length) {
                        case 1: {
                            listLabelBefore[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabelBefore[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabelBefore[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabelBefore[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabelBefore[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabelBefore[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabelBefore[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabelBefore[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabelBefore[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabelBefore[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabelBefore[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabelBefore[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabelBefore[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabelBefore[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabelBefore[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        // No default
                    }

                    switch (listLabelAfter.length) {
                        case 1: {
                            listLabelAfter[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabelAfter[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabelAfter[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabelAfter[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabelAfter[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabelAfter[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabelAfter[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabelAfter[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabelAfter[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabelAfter[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabelAfter[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabelAfter[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabelAfter[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabelAfter[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabelAfter[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        // No default
                    }

                } else {
                    const currentLink = d3Data.g.select(`#relationshipID_${relatedTo}`)
                    currentLink.select(`#simplePathUpdate_${relatedTo}`).remove();

                    const currentAuthRelAction = d3Data.g.select(`#actionRelationship_${relatedTo}`)
                    const currentAuthRelVC = d3Data.g.select(`#vcRelationship_${relatedTo}`)
                    const currentAuthSimplePath = d3Data.g.select(`#simplePath_${relatedTo}`)
                    const currentAuthSimplePathCreate = d3Data.g.select(`#simplePathCreate_${relatedTo}`)
                    const currentAuthSimplePathRead = d3Data.g.select(`#simplePathUpdate_${relatedTo}`)
                    const currentAuthSimplePathDelete = d3Data.g.select(`#simplePathDelete_${relatedTo}`)

                    const listLabel = [];

                    if (currentAuthSimplePath.node()) {
                        listLabel.push(currentAuthSimplePath)
                    }

                    if (currentAuthSimplePathCreate.node()) {
                        listLabel.push(currentAuthSimplePathCreate)
                    }

                    if (currentAuthSimplePathRead.node()) {
                        listLabel.push(currentAuthSimplePathRead)
                    }

                    if (currentAuthSimplePathDelete.node()) {
                        listLabel.push(currentAuthSimplePathDelete)
                    }

                    if (currentAuthRelAction.node()) {
                        listLabel.push(currentAuthRelAction)
                    }

                    if (currentAuthRelVC.node()) {
                        listLabel.push(currentAuthRelVC)
                    }

                    switch (listLabel.length) {
                        case 0: {
                            currentLink.classed("setColorLinePetal", false);
                            currentLink.classed("setColorPath", false);

                        break;
                        }
                        case 1: {
                            listLabel[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabel[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabel[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabel[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabel[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabel[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabel[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabel[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabel[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabel[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabel[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabel[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabel[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabel[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabel[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        case 6: {
                            listLabel[5].attr("startOffset", "84%").attr("font-size", "10px");
                            listLabel[4].attr("startOffset", "70%").attr("font-size", "10px");
                            listLabel[3].attr("startOffset", "56%").attr("font-size", "10px");
                            listLabel[2].attr("startOffset", "42%").attr("font-size", "10px");
                            listLabel[1].attr("startOffset", "28%").attr("font-size", "10px");
                            listLabel[0].attr("startOffset", "14%").attr("font-size", "10px");

                        break;
                        }
                        case 7: {
                            listLabel[6].attr("startOffset", "86.5%").attr("font-size", "9px");
                            listLabel[5].attr("startOffset", "75%").attr("font-size", "9px");
                            listLabel[4].attr("startOffset", "62.2%").attr("font-size", "9px");
                            listLabel[3].attr("startOffset", "50%").attr("font-size", "9px");
                            listLabel[2].attr("startOffset", "37.5%").attr("font-size", "9px");
                            listLabel[1].attr("startOffset", "25%").attr("font-size", "9px");
                            listLabel[0].attr("startOffset", "12.5%").attr("font-size", "9px");

                        break;
                        }
                        // No default
                        }
                }

                onDelete({
                    id: relatedTo,
                    type: EDeleteType.SIMPLE_PATH_UPDATE
                });
            }
        });
    };

    const deleteAuthSimplePathDelete = (orbitID: string, relatedTo: string): void => {
        void deleteSimplePathDelete({
            variables: { _id: orbitID }
        }).then((res) => {
            if (res.data.removeSimplePathDeleteAuthZLabelFromRelationship) {
                const nr = data.nodeRelationships.find(nr => nr._id === relatedTo)

                if (nr) {
                    const selectedOrbit = d3Data.g.select(`#orbitID_${relatedTo}`)
                    selectedOrbit.select(`#authPathDelete_${relatedTo}`).remove();

                    const currentLinkBeforeNR = d3Data.g.select(`#relationshipID_${nr.relation.relFrom._id}`)
                    currentLinkBeforeNR.select(`#simplePathDelete_${nr.relation.relFrom._id}`).remove();

                    const currentLinkAfterNR = d3Data.g.select(`#relationshipID_${nr.relation.relTo._id}`)
                    currentLinkAfterNR.select(`#simplePathDelete_${nr.relation.relTo._id}`).remove();

                    const currentLinkBeforeNRPath = d3Data.g.select(`#simplePath_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPath = d3Data.g.select(`#simplePath_${nr.relation.relTo._id}`)
                    const currentLinkBeforeNRPathCreate = d3Data.g.select(`#simplePathCreate_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathCreate = d3Data.g.select(`#simplePathCreate_${nr.relation.relTo._id}`)
                    const currentLinkBeforeNRPathRead = d3Data.g.select(`#simplePathRead_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathRead = d3Data.g.select(`#simplePathRead_${nr.relation.relTo._id}`)
                    const currentLinkBeforeNRPathUpdate = d3Data.g.select(`#simplePathUpdate_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathUpdate = d3Data.g.select(`#simplePathUpdate_${nr.relation.relTo._id}`)
                    const currentLinkBeforeNRPathDelete = d3Data.g.select(`#simplePathDelete_${nr.relation.relFrom._id}`)
                    const currentLinkAfterNRPathDelete = d3Data.g.select(`#simplePathDelete_${nr.relation.relTo._id}`)

                    const listLabelBefore = [];
                    const listLabelAfter = [];
                    if(currentLinkBeforeNRPath.node()){
                        listLabelBefore.push(currentLinkBeforeNRPath);
                    }
                    if(currentLinkBeforeNRPathCreate.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathCreate);
                    }
                    if(currentLinkBeforeNRPathRead.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathRead);
                    }
                    if(currentLinkBeforeNRPathUpdate.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathUpdate);
                    }
                    if(currentLinkBeforeNRPathDelete.node()){
                        listLabelBefore.push(currentLinkBeforeNRPathDelete);
                    }

                    if(currentLinkAfterNRPath.node()){
                        listLabelAfter.push(currentLinkAfterNRPath);
                    }
                    if(currentLinkAfterNRPathCreate.node()){
                        listLabelAfter.push(currentLinkAfterNRPathCreate);
                    }
                    if(currentLinkAfterNRPathRead.node()){
                        listLabelAfter.push(currentLinkAfterNRPathRead);
                    }
                    if(currentLinkAfterNRPathUpdate.node()){
                        listLabelAfter.push(currentLinkAfterNRPathUpdate);
                    }
                    if(currentLinkAfterNRPathDelete.node()){
                        listLabelAfter.push(currentLinkAfterNRPathDelete);
                    }
                    if(listLabelBefore.length === 0 || listLabelAfter.length === 0){
                        selectedOrbit.classed("setColorIsPath", false);
                        currentLinkBeforeNR.classed("setColorPath", false);
                        currentLinkAfterNR.classed("setColorPath", false);
                    }

                    switch (listLabelBefore.length) {
                        case 1: {
                            listLabelBefore[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabelBefore[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabelBefore[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabelBefore[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabelBefore[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabelBefore[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabelBefore[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabelBefore[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabelBefore[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabelBefore[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabelBefore[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabelBefore[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabelBefore[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabelBefore[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabelBefore[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        // No default
                    }

                    switch (listLabelAfter.length) {
                        case 1: {
                            listLabelAfter[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabelAfter[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabelAfter[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabelAfter[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabelAfter[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabelAfter[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabelAfter[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabelAfter[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabelAfter[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabelAfter[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabelAfter[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabelAfter[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabelAfter[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabelAfter[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabelAfter[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        // No default
                    }

                } else {
                    const currentLink = d3Data.g.select(`#relationshipID_${relatedTo}`)
                    currentLink.select(`#simplePathDelete_${relatedTo}`).remove();

                    const currentAuthRelAction = d3Data.g.select(`#actionRelationship_${relatedTo}`)
                    const currentAuthRelVC = d3Data.g.select(`#vcRelationship_${relatedTo}`)
                    const currentAuthSimplePath = d3Data.g.select(`#simplePath_${relatedTo}`)
                    const currentAuthSimplePathCreate = d3Data.g.select(`#simplePathCreate_${relatedTo}`)
                    const currentAuthSimplePathUpdate = d3Data.g.select(`#simplePathRead_${relatedTo}`)
                    const currentAuthSimplePathRead = d3Data.g.select(`#simplePathUpdate_${relatedTo}`)

                    const listLabel = [];

                    if (currentAuthSimplePath.node()) {
                        listLabel.push(currentAuthSimplePath)
                    }

                    if (currentAuthSimplePathCreate.node()) {
                        listLabel.push(currentAuthSimplePathCreate)
                    }

                    if (currentAuthSimplePathUpdate.node()) {
                        listLabel.push(currentAuthSimplePathUpdate)
                    }

                    if (currentAuthSimplePathRead.node()) {
                        listLabel.push(currentAuthSimplePathRead)
                    }

                    if (currentAuthRelAction.node()) {
                        listLabel.push(currentAuthRelAction)
                    }

                    if (currentAuthRelVC.node()) {
                        listLabel.push(currentAuthRelVC)
                    }

                    switch (listLabel.length) {
                        case 0: {
                            currentLink.classed("setColorLinePetal", false);
                            currentLink.classed("setColorPath", false);

                        break;
                        }
                        case 1: {
                            listLabel[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabel[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabel[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabel[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabel[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabel[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabel[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabel[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabel[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabel[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabel[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabel[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabel[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabel[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabel[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        case 6: {
                            listLabel[5].attr("startOffset", "84%").attr("font-size", "10px");
                            listLabel[4].attr("startOffset", "70%").attr("font-size", "10px");
                            listLabel[3].attr("startOffset", "56%").attr("font-size", "10px");
                            listLabel[2].attr("startOffset", "42%").attr("font-size", "10px");
                            listLabel[1].attr("startOffset", "28%").attr("font-size", "10px");
                            listLabel[0].attr("startOffset", "14%").attr("font-size", "10px");

                        break;
                        }
                        case 7: {
                            listLabel[6].attr("startOffset", "86.5%").attr("font-size", "9px");
                            listLabel[5].attr("startOffset", "75%").attr("font-size", "9px");
                            listLabel[4].attr("startOffset", "62.2%").attr("font-size", "9px");
                            listLabel[3].attr("startOffset", "50%").attr("font-size", "9px");
                            listLabel[2].attr("startOffset", "37.5%").attr("font-size", "9px");
                            listLabel[1].attr("startOffset", "25%").attr("font-size", "9px");
                            listLabel[0].attr("startOffset", "12.5%").attr("font-size", "9px");

                        break;
                        }
                        // No default
                        }

                }

                onDelete({
                    id: relatedTo,
                    type: EDeleteType.SIMPLE_PATH_DELETE
                });
            }
        });
    };

    const deleteAuthActionRelationship = (orbitID: string): void => {
        void deleteActionRelationship({
            variables: { targetID: orbitID }
        }).then((res) => {
            if (res.data.removeActionAuthZLabelFromRelationship) {
                const nr = data.nodeRelationships.find(nr => nr._id === orbitID)

                if (nr) {
                    const selectedOrbit = d3Data.g.select(`#orbitID_${orbitID}`)
                    selectedOrbit.select(`#actionRelationship_${orbitID}`).remove();

                    selectedOrbit.select(`#authAction_${orbitID}`).remove();
                    const currentAuthRelVC = d3Data.g.select(`#authVC_${orbitID}`)
                    const listLabel = [];

                    if (currentAuthRelVC.node()) {
                        listLabel.push(currentAuthRelVC)
                    }

                    // if (listLabel.length === 0) {
                    //     currentLink.classed("setColorPath", false);
                    // }

                    if (listLabel.length === 1) {
                        listLabel[0].attr("startOffset", "50%").attr("font-size", "16px");
                    }

                    if (listLabel.length === 2) {
                        listLabel[1].attr("startOffset", "70%").attr("font-size", "14px");
                        listLabel[0].attr("startOffset", "30%").attr("font-size", "14px");
                    }

                    if (!(nr.simplePath || nr.simplePathCreate || nr.simplePathRead || nr.simplePathUpdate || nr.simplePathDelete)) {
                        selectedOrbit.classed("setColorIsPath", false);
                    }

                } else {
                    const currentLink = d3Data.g.select(`#relationshipID_${orbitID}`)
                    currentLink.select(`#actionRelationship_${orbitID}`).remove();

                    const currentAuthSimplePath = d3Data.g.select(`#simplePath_${orbitID}`)
                    const currentAuthRelVC = d3Data.g.select(`#vcRelationship_${orbitID}`)
                    const currentAuthSimplePathCreate = d3Data.g.select(`#simplePathCreate_${orbitID}`)
                    const currentAuthSimplePathUpdate = d3Data.g.select(`#simplePathRead_${orbitID}`)
                    const currentAuthSimplePathRead = d3Data.g.select(`#simplePathUpdate_${orbitID}`)
                    const currentAuthSimplePathDelete = d3Data.g.select(`#simplePathDelete_${orbitID}`)

                    const listLabel = [];

                    if (currentAuthSimplePathCreate.node()) {
                        listLabel.push(currentAuthSimplePathCreate)
                    }

                    if (currentAuthSimplePathUpdate.node()) {
                        listLabel.push(currentAuthSimplePathUpdate)
                    }

                    if (currentAuthSimplePathRead.node()) {
                        listLabel.push(currentAuthSimplePathRead)
                    }

                    if (currentAuthSimplePathDelete.node()) {
                        listLabel.push(currentAuthSimplePathDelete)
                    }

                    if (currentAuthSimplePath.node()) {
                        listLabel.push(currentAuthSimplePath)
                    }

                    if (currentAuthRelVC.node()) {
                        listLabel.push(currentAuthRelVC)
                    }

                    switch (listLabel.length) {
                        case 0: {
                            currentLink.classed("setColorLinePetal", false);
                            currentLink.classed("setColorPath", false);

                        break;
                        }
                        case 1: {
                            listLabel[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabel[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabel[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabel[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabel[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabel[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabel[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabel[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabel[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabel[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabel[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabel[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabel[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabel[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabel[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        case 6: {
                            listLabel[5].attr("startOffset", "84%").attr("font-size", "10px");
                            listLabel[4].attr("startOffset", "70%").attr("font-size", "10px");
                            listLabel[3].attr("startOffset", "56%").attr("font-size", "10px");
                            listLabel[2].attr("startOffset", "42%").attr("font-size", "10px");
                            listLabel[1].attr("startOffset", "28%").attr("font-size", "10px");
                            listLabel[0].attr("startOffset", "14%").attr("font-size", "10px");

                        break;
                        }
                        case 7: {
                            listLabel[6].attr("startOffset", "86.5%").attr("font-size", "9px");
                            listLabel[5].attr("startOffset", "75%").attr("font-size", "9px");
                            listLabel[4].attr("startOffset", "62.2%").attr("font-size", "9px");
                            listLabel[3].attr("startOffset", "50%").attr("font-size", "9px");
                            listLabel[2].attr("startOffset", "37.5%").attr("font-size", "9px");
                            listLabel[1].attr("startOffset", "25%").attr("font-size", "9px");
                            listLabel[0].attr("startOffset", "12.5%").attr("font-size", "9px");

                        break;
                        }
                        // No default
                        }
                }

                onDelete({
                    id: orbitID,
                    type: EDeleteType.AUTHZ_ACTION
                });
            }
        });
    };

    const deleteAuthVCRelationship = (orbitID: string): void => {
        void deleteVCRelationship({
            variables: { targetID: orbitID }
        }).then((res) => {
            if (res.data.removeVCAuthZLabelFromRelationship) {
                const nr = data.nodeRelationships.find(nr => nr._id === orbitID)

                if (nr) {
                    const selectedOrbit = d3Data.g.select(`#orbitID_${orbitID}`)
                    selectedOrbit.select(`#vcRelationship_${orbitID}`).remove();
                    selectedOrbit.select(`#authVC_${orbitID}`).remove();

                    const currentAuthRelAction = d3Data.g.select(`#authAction_${orbitID}`)
                    const listLabel = [];

                    if (currentAuthRelAction.node()) {
                        listLabel.push(currentAuthRelAction)
                    }

                    // if (listLabel.length === 0) {
                    //     currentLink.classed("setColorPath", false);
                    // }

                    if (listLabel.length === 1) {
                        listLabel[0].attr("startOffset", "50%").attr("font-size", "16px");
                    }

                    if (listLabel.length === 2) {
                        listLabel[1].attr("startOffset", "70%").attr("font-size", "14px");
                        listLabel[0].attr("startOffset", "30%").attr("font-size", "14px");
                    }

                    if (!(nr.simplePath || nr.simplePathCreate || nr.simplePathRead || nr.simplePathUpdate || nr.simplePathDelete)) {
                        selectedOrbit.classed("setColorIsPath", false);
                    }

                } else {
                    const currentLink = d3Data.g.select(`#relationshipID_${orbitID}`)
                    currentLink.select(`#vcRelationship_${orbitID}`).remove();

                    const currentAuthSimplePath = d3Data.g.select(`#simplePath_${orbitID}`)
                    const currentAuthRelAction = d3Data.g.select(`#actionRelationship_${orbitID}`)
                    const currentAuthSimplePathCreate = d3Data.g.select(`#simplePathCreate_${orbitID}`)
                    const currentAuthSimplePathUpdate = d3Data.g.select(`#simplePathRead_${orbitID}`)
                    const currentAuthSimplePathRead = d3Data.g.select(`#simplePathUpdate_${orbitID}`)
                    const currentAuthSimplePathDelete = d3Data.g.select(`#simplePathDelete_${orbitID}`)

                    const listLabel = [];

                    if (currentAuthSimplePathCreate.node()) {
                        listLabel.push(currentAuthSimplePathCreate)
                    }

                    if (currentAuthSimplePathUpdate.node()) {
                        listLabel.push(currentAuthSimplePathUpdate)
                    }

                    if (currentAuthSimplePathRead.node()) {
                        listLabel.push(currentAuthSimplePathRead)
                    }

                    if (currentAuthSimplePathDelete.node()) {
                        listLabel.push(currentAuthSimplePathDelete)
                    }

                    if (currentAuthSimplePath.node()) {
                        listLabel.push(currentAuthSimplePath)
                    }

                    if (currentAuthRelAction.node()) {
                        listLabel.push(currentAuthRelAction)
                    }

                    switch (listLabel.length) {
                        case 0: {
                            currentLink.classed("setColorLinePetal", false);
                            currentLink.classed("setColorPath", false);

                        break;
                        }
                        case 1: {
                            listLabel[0].attr("startOffset", "50%").attr("font-size", "16px");

                        break;
                        }
                        case 2: {
                            listLabel[1].attr("startOffset", "66%").attr("font-size", "14px");
                            listLabel[0].attr("startOffset", "33%").attr("font-size", "14px");

                        break;
                        }
                        case 3: {
                            listLabel[2].attr("startOffset", "75%").attr("font-size", "13px");
                            listLabel[1].attr("startOffset", "50%").attr("font-size", "13px");
                            listLabel[0].attr("startOffset", "25%").attr("font-size", "13px");

                        break;
                        }
                        case 4: {
                            listLabel[3].attr("startOffset", "80%").attr("font-size", "12px");
                            listLabel[2].attr("startOffset", "60%").attr("font-size", "12px");
                            listLabel[1].attr("startOffset", "40%").attr("font-size", "12px");
                            listLabel[0].attr("startOffset", "20%").attr("font-size", "12px");

                        break;
                        }
                        case 5: {
                            listLabel[4].attr("startOffset", "82%").attr("font-size", "11px");
                            listLabel[3].attr("startOffset", "66%").attr("font-size", "11px");
                            listLabel[2].attr("startOffset", "50%").attr("font-size", "11px");
                            listLabel[1].attr("startOffset", "32%").attr("font-size", "11px");
                            listLabel[0].attr("startOffset", "16%").attr("font-size", "11px");

                        break;
                        }
                        case 6: {
                            listLabel[5].attr("startOffset", "84%").attr("font-size", "10px");
                            listLabel[4].attr("startOffset", "70%").attr("font-size", "10px");
                            listLabel[3].attr("startOffset", "56%").attr("font-size", "10px");
                            listLabel[2].attr("startOffset", "42%").attr("font-size", "10px");
                            listLabel[1].attr("startOffset", "28%").attr("font-size", "10px");
                            listLabel[0].attr("startOffset", "14%").attr("font-size", "10px");

                        break;
                        }
                        case 7: {
                            listLabel[6].attr("startOffset", "86.5%").attr("font-size", "9px");
                            listLabel[5].attr("startOffset", "75%").attr("font-size", "9px");
                            listLabel[4].attr("startOffset", "62.2%").attr("font-size", "9px");
                            listLabel[3].attr("startOffset", "50%").attr("font-size", "9px");
                            listLabel[2].attr("startOffset", "37.5%").attr("font-size", "9px");
                            listLabel[1].attr("startOffset", "25%").attr("font-size", "9px");
                            listLabel[0].attr("startOffset", "12.5%").attr("font-size", "9px");

                        break;
                        }
                        // No default
                        }
                }

                onDelete({
                    id: orbitID,
                    type: EDeleteType.AUTHZ_VC
                });
            }
        });
    };


    const deleteAuthSubject = (orbitID: string, relatedTo: string): void => {
        void deleteSubject({
            variables: { targetID: orbitID }
        }).then((res) => {
            if (res.data.removeSubjectAuthZLabelFromNode) {
                const selectedOrbit = d3Data.g.select(`#orbitID_${orbitID}`)
                const currentAuthAction = d3Data.g.select(`#authAction_${orbitID}`)
                const currentAuthResource = d3Data.g.select(`#authResource_${orbitID}`)
                const currentAuthVC = d3Data.g.select(`#authVC_${orbitID}`)

                d3Data.g.select(`#authSubject_${orbitID}`).remove()
                selectedOrbit.classed("setColorIsSubject", false);

                const listLabel = [];

                if (currentAuthResource.node()) {
                    listLabel.push(currentAuthResource)
                }

                if (currentAuthAction.node()) {
                    listLabel.push(currentAuthAction)
                }

                if (currentAuthVC.node()) {
                    listLabel.push(currentAuthVC)
                }

                if (listLabel.length === 1) {
                    listLabel[0].attr("y", -50).attr("font-size", "16px");
                }

                if (listLabel.length === 2) {
                    listLabel[1].attr("y", -60).attr("font-size", "14px");
                    listLabel[0].attr("y", -45).attr("font-size", "14px");
                }

                if (listLabel.length === 3) {
                    listLabel[2].attr("y", -75).attr("font-size", "13px");
                    listLabel[1].attr("y", -60).attr("font-size", "13px");
                    listLabel[0].attr("y", -45).attr("font-size", "13px");
                }

                onDelete({
                    id: relatedTo,
                    type: EDeleteType.AUTHZ_SUBJECT
                });

                d3Data.g.selectAll(".orbit").call(enters.updateOrbit);
            }
        });
    };

    const deleteAuthResource = (orbitID: string, relatedTo: string): void => {
        void deleteResource({
            variables: { targetID: orbitID }
        }).then((res) => {
            if (res.data.removeObjectAuthZLabelFromNode) {
                const selectedOrbit = d3Data.g.select(`#orbitID_${orbitID}`)
                const currentAuthSubject = d3Data.g.select(`#authSubject_${orbitID}`)
                const currentAuthAction = d3Data.g.select(`#authAction_${orbitID}`)
                const currentAuthVC = d3Data.g.select(`#authVC_${orbitID}`)

                d3Data.g.select(`#authResource_${orbitID}`).remove()
                selectedOrbit.classed("setColorIsResource", false);

                const listLabel = [];

                if (currentAuthSubject.node()) {
                    listLabel.push(currentAuthSubject)
                }

                if (currentAuthAction.node()) {
                    listLabel.push(currentAuthAction)
                }

                if (currentAuthVC.node()) {
                    listLabel.push(currentAuthVC)
                }

                if (listLabel.length === 1) {
                    listLabel[0].attr("y", -50).attr("font-size", "16px");
                }

                if (listLabel.length === 2) {
                    listLabel[1].attr("y", -60).attr("font-size", "14px");
                    listLabel[0].attr("y", -45).attr("font-size", "14px");
                }

                if (listLabel.length === 3) {
                    listLabel[2].attr("y", -75).attr("font-size", "13px");
                    listLabel[1].attr("y", -60).attr("font-size", "13px");
                    listLabel[0].attr("y", -45).attr("font-size", "13px");
                }

                const currentCM = data.customMutations.filter(cm => cm._id === orbitID);

                if (currentCM.length > 0) {
                    const pathCM = data.paths.filter(p => p.target._id === orbitID);

                    if (pathCM.length > 0) {
                        deleteAuthPaths(pathCM[0]._id)
                    }
                }

                onDelete({
                    id: relatedTo,
                    type: EDeleteType.AUTHZ_RESOURCE
                });
            }
        });
    };

    const deleteAuthAction = (orbitID: string, relatedTo: string): void => {
        void deleteAction({
            variables: { targetID: orbitID }
        }).then((res) => {
            if (res.data.removeActionAuthZLabelFromNode) {
                const selectedOrbit = d3Data.g.select(`#orbitID_${orbitID}`)
                const currentAuthSubject = d3Data.g.select(`#authSubject_${orbitID}`)
                const currentAuthResource = d3Data.g.select(`#authResource_${orbitID}`)
                const currentAuthVC = d3Data.g.select(`#authVC_${orbitID}`)

                d3Data.g.select(`#authAction_${orbitID}`).remove()
                selectedOrbit.classed("setColorIsAction", false);

                const listLabel = [];

                if (currentAuthSubject.node()) {
                    listLabel.push(currentAuthSubject)
                }

                if (currentAuthResource.node()) {
                    listLabel.push(currentAuthResource)
                }

                if (currentAuthVC.node()) {
                    listLabel.push(currentAuthVC)
                }

                if (listLabel.length === 1) {
                    listLabel[0].attr("y", -50).attr("font-size", "16px");
                }

                if (listLabel.length === 2) {
                    listLabel[1].attr("y", -60).attr("font-size", "14px");
                    listLabel[0].attr("y", -45).attr("font-size", "14px");
                }

                if (listLabel.length === 3) {
                    listLabel[2].attr("y", -75).attr("font-size", "13px");
                    listLabel[1].attr("y", -60).attr("font-size", "13px");
                    listLabel[0].attr("y", -45).attr("font-size", "13px");
                }

                onDelete({
                    id: relatedTo,
                    type: EDeleteType.AUTHZ_ACTION
                });

                d3Data.g.selectAll(".orbit").call(enters.updateOrbit);
            }
        });
    };

    const deleteAuthVC = (orbitID: string, relatedTo: string): void => {
        void deleteVC({
            variables: { targetID: orbitID }
        }).then((res) => {
            if (res.data.removeVCAuthZLabelFromNode) {
                const selectedOrbit = d3Data.g.select(`#orbitID_${orbitID}`)
                const currentAuthAction = d3Data.g.select(`#authAction_${orbitID}`)
                const currentAuthSubject = d3Data.g.select(`#authSubject_${orbitID}`)
                const currentAuthResource = d3Data.g.select(`#authResource_${orbitID}`)

                d3Data.g.select(`#authVC_${orbitID}`).remove()
                selectedOrbit.classed("setColorIsVC", false);

                const listLabel = [];

                if (currentAuthSubject.node()) {
                    listLabel.push(currentAuthSubject)
                }

                if (currentAuthResource.node()) {
                    listLabel.push(currentAuthResource)
                }

                if (currentAuthAction.node()) {
                    listLabel.push(currentAuthAction)
                }

                if (listLabel.length === 1) {
                    listLabel[0].attr("y", -50).attr("font-size", "16px");
                }

                if (listLabel.length === 2) {
                    listLabel[1].attr("y", -60).attr("font-size", "14px");
                    listLabel[0].attr("y", -45).attr("font-size", "14px");
                }

                if (listLabel.length === 3) {
                    listLabel[2].attr("y", -75).attr("font-size", "13px");
                    listLabel[1].attr("y", -60).attr("font-size", "13px");
                    listLabel[0].attr("y", -45).attr("font-size", "13px");
                }

                onDelete({
                    id: relatedTo,
                    type: EDeleteType.AUTHZ_VC
                });

                d3Data.g.selectAll(".orbit").call(enters.updateOrbit);
            }
        });
    };

    const deleteAuthHolder = (orbitID: string, relatedTo: string): void => {
        void deleteHolder({
            variables: { targetID: orbitID }
        }).then((res) => {
            if (res.data.removeHolderAuthZLabelFromNode) {
                const selectedOrbit = d3Data.g.select(`#orbitID_${orbitID}`)
                const currentAuthAction = d3Data.g.select(`#authAction_${orbitID}`)
                const currentAuthSubject = d3Data.g.select(`#authSubject_${orbitID}`)
                const currentAuthResource = d3Data.g.select(`#authResource_${orbitID}`)

                d3Data.g.select(`#authHolder_${orbitID}`).remove()
                selectedOrbit.classed("setColorIsHolder", false);

                const listLabel = [];

                if (currentAuthSubject.node()) {
                    listLabel.push(currentAuthSubject)
                }

                if (currentAuthResource.node()) {
                    listLabel.push(currentAuthResource)
                }

                if (currentAuthAction.node()) {
                    listLabel.push(currentAuthAction)
                }

                if (listLabel.length === 1) {
                    listLabel[0].attr("y", -50).attr("font-size", "16px");
                }

                if (listLabel.length === 2) {
                    listLabel[1].attr("y", -60).attr("font-size", "14px");
                    listLabel[0].attr("y", -45).attr("font-size", "14px");
                }

                if (listLabel.length === 3) {
                    listLabel[2].attr("y", -75).attr("font-size", "13px");
                    listLabel[1].attr("y", -60).attr("font-size", "13px");
                    listLabel[0].attr("y", -45).attr("font-size", "13px");
                }

                onDelete({
                    id: relatedTo,
                    type: EDeleteType.AUTHZ_HOLDER
                });

                d3Data.g.selectAll(".orbit").call(enters.updateOrbit);
            }
        });
    };

    const deleteAuthIssuer = (orbitID: string, relatedTo: string): void => {
        void deleteIssuer({
            variables: { targetID: orbitID }
        }).then((res) => {
            if (res.data.removeIssuerAuthZLabelFromNode) {
                const selectedOrbit = d3Data.g.select(`#orbitID_${orbitID}`)
                const currentAuthAction = d3Data.g.select(`#authAction_${orbitID}`)
                const currentAuthSubject = d3Data.g.select(`#authSubject_${orbitID}`)
                const currentAuthResource = d3Data.g.select(`#authResource_${orbitID}`)

                d3Data.g.select(`#authIssuer_${orbitID}`).remove()
                selectedOrbit.classed("setColorIsIssuer", false);

                const listLabel = [];

                if (currentAuthSubject.node()) {
                    listLabel.push(currentAuthSubject)
                }

                if (currentAuthResource.node()) {
                    listLabel.push(currentAuthResource)
                }

                if (currentAuthAction.node()) {
                    listLabel.push(currentAuthAction)
                }

                if (listLabel.length === 1) {
                    listLabel[0].attr("y", -50).attr("font-size", "16px");
                }

                if (listLabel.length === 2) {
                    listLabel[1].attr("y", -60).attr("font-size", "14px");
                    listLabel[0].attr("y", -45).attr("font-size", "14px");
                }

                if (listLabel.length === 3) {
                    listLabel[2].attr("y", -75).attr("font-size", "13px");
                    listLabel[1].attr("y", -60).attr("font-size", "13px");
                    listLabel[0].attr("y", -45).attr("font-size", "13px");
                }

                onDelete({
                    id: relatedTo,
                    type: EDeleteType.AUTHZ_ISSUER
                });

                d3Data.g.selectAll(".orbit").call(enters.updateOrbit);
            }
        });
    };

    const deleteAuthClaim = (orbitID: string, relatedTo: string): void => {
        void deleteClaim({
            variables: { targetID: orbitID }
        }).then((res) => {
            if (res.data.removeClaimAuthZLabelFromNode) {
                const selectedOrbit = d3Data.g.select(`#orbitID_${orbitID}`)
                const currentAuthAction = d3Data.g.select(`#authAction_${orbitID}`)
                const currentAuthSubject = d3Data.g.select(`#authSubject_${orbitID}`)
                const currentAuthResource = d3Data.g.select(`#authResource_${orbitID}`)

                d3Data.g.select(`#authClaim_${orbitID}`).remove()
                selectedOrbit.classed("setColorIsClaim", false);

                const listLabel = [];

                if (currentAuthSubject.node()) {
                    listLabel.push(currentAuthSubject)
                }

                if (currentAuthResource.node()) {
                    listLabel.push(currentAuthResource)
                }

                if (currentAuthAction.node()) {
                    listLabel.push(currentAuthAction)
                }

                if (listLabel.length === 1) {
                    listLabel[0].attr("y", -50).attr("font-size", "16px");
                }

                if (listLabel.length === 2) {
                    listLabel[1].attr("y", -60).attr("font-size", "14px");
                    listLabel[0].attr("y", -45).attr("font-size", "14px");
                }

                if (listLabel.length === 3) {
                    listLabel[2].attr("y", -75).attr("font-size", "13px");
                    listLabel[1].attr("y", -60).attr("font-size", "13px");
                    listLabel[0].attr("y", -45).attr("font-size", "13px");
                }

                onDelete({
                    id: relatedTo,
                    type: EDeleteType.AUTHZ_CLAIM
                });

                d3Data.g.selectAll(".orbit").call(enters.updateOrbit);
            }
        });
    };

    const contextMenuCB = (e: React.MouseEvent, orbit: OrbitItem): void => {
        e.preventDefault();
        e.stopPropagation();
        let menuOptions: IMenuOption[] = [];
        setContextMenuNotVisible();

        if (isShowDashboard) {
            if (orbit.__typename === NodeTypename.NIAM_OBJECT) {
                const objParent = data.objects.find(item => item._id === orbit._id)

                if (data.interfaces.length > 0) {
                    menuOptions.push({
                        key: "objectRightClickItemInterface",
                        image: "InterfaceIcon",
                        title: t("rightClick:implement.interface"),
                        type: MenuOptionsType.ADD,
                        nestedMenuOptions: [
                            ...data.interfaces
                                .filter((f) => !objParent.interfaces.map((inter) => inter._id).includes(f._id))
                                .map((item): IMenuOption => ({
                                    title: item.displayName,
                                    key: randomKey(),
                                    image: "InterfaceIcon",
                                    type: MenuOptionsType.ADD,
                                    onClick: () => {
                                        const interParent = data.interfaces.find(f => f._id === item._id)
                                        let fieldsAlreadyExists = false;

                                        interParent.fields.map(f => {
                                            objParent.interfaces.forEach(inter => {
                                                if (interParent._id !== inter._id) {
                                                    const interCurrent = data.interfaces.find(f => f._id === inter._id)
                                                    const alreadyExists = interCurrent.fields.find(f => f.name === item.name);

                                                    if (alreadyExists) {
                                                        fieldsAlreadyExists = true
                                                    }
                                                }
                                            })

                                            const alreadyExists = objParent.fields.find(f => f.name === item.name);

                                            if (alreadyExists) {
                                                fieldsAlreadyExists = true
                                            }
                                        })

                                        if (fieldsAlreadyExists) {
                                            toastWarning(t(`validations:interface.fields.already.exists`));
                                            return
                                        }

                                        let counter = 1;
                                        interParent.fields.forEach((field) => {
                                            if (field.relatedTo === item._id) {
                                                counter++;
                                            }
                                        });

                                        void implementInterface({
                                            variables: {
                                                serverID: server._id,
                                                niamInterfaceID: item._id,
                                                niamObjectID: objParent._id
                                            }
                                        }).then(({ errors }: any) => {
                                            setContextMenuNotVisible();

                                            if (errors) {
                                                for (const e of errors) {
                                                    toastError(t(`validations:${e.message}`));
                                                }

                                                return;
                                            }

                                            void addD3Item({
                                                type: RelationshipType.IMPLEMENT,
                                                newItemData: {
                                                    curve: setCurve(counter),
                                                    source: objParent,
                                                    target: item,
                                                    type: RelationshipType.IMPLEMENT,
                                                    _id: `${item._id}${objParent._id}`,
                                                    isShown: true
                                                }
                                            });
                                        });
                                    }
                                }))
                        ]
                    });
                }

                menuOptions.push(
                    {
                        key: "objectRightClickItemRelationship",
                        image: "PropertyIcon",
                        title: t("rightClick:add.relationship"),
                        type: MenuOptionsType.ADD,
                        nestedMenuOptions: [
                            ...data.interfaces.map((item): IMenuOption => ({
                                title: item.displayName,
                                key: randomKey(),
                                image: "InterfaceIcon",
                                type: MenuOptionsType.ADD,
                                onClick: () => {
                                    const { defaultName } = setDefaultName(objParent.name, item.name, objParent.fields, item);

                                    let counter = 1;
                                    objParent.fields.forEach((field) => {
                                        if (field.relatedTo === item._id) {
                                            counter++;
                                        }
                                    });

                                    item.fields.forEach((field) => {
                                        if (field.relatedTo === objParent._id) {
                                            counter++;
                                        }
                                    });

                                    void addNewPropertyToObjectMutation({
                                        variables: {
                                            NiamObjectID: objParent._id,
                                            newNiamFieldInput: {
                                                fieldType: NiamFieldTypeEnum.Relationship,
                                                name: defaultName,
                                                displayName: defaultName,
                                                isArray: false,
                                                isRequired: false,
                                                isNaming: false,
                                                isSecret: false,
                                                isDisplay: false,
                                                relatedTo: item._id
                                            }
                                        }
                                    }).then(({ data: dataPropertyObject, errors }: any) => {
                                        setContextMenuNotVisible();

                                        if (errors) {
                                            for (const er of errors) {
                                                toastError(t(`validations:${er.message}`));
                                            }

                                            return;
                                        }

                                        void addD3Item({
                                            type: RelationshipType.RELATION,
                                            newItemData: {
                                                curve: setCurve(counter),
                                                source: objParent,
                                                target: item,
                                                _id: dataPropertyObject.addNewFieldToNiamObject._id,
                                                fieldType: NiamFieldTypeEnum.Relationship,
                                                isShown: true,
                                                displayName: defaultName,
                                                name: defaultName,
                                                relatedTo: item._id
                                            }
                                        });
                                    });
                                }
                            })),
                            ...data.objects.map((item): IMenuOption => ({
                                title: item.displayName,
                                key: randomKey(),
                                image: "ObjectIcon",
                                type: MenuOptionsType.ADD,
                                onClick: () => {
                                    const { defaultName } = setDefaultName(objParent.name, `R_${item.name}`, objParent.fields, item);

                                    let counter = 1;
                                    objParent.fields.forEach((field) => {
                                        if (field.relatedTo === item._id) {
                                            counter++;
                                        }
                                    });

                                    item.fields.forEach((field) => {
                                        if (field.relatedTo === objParent._id) {
                                            counter++;
                                        }
                                    });

                                    void addNewPropertyToObjectMutation({
                                        variables: {
                                            NiamObjectID: objParent._id,
                                            newNiamFieldInput: {
                                                fieldType: NiamFieldTypeEnum.Relationship,
                                                name: defaultName,
                                                displayName: defaultName,
                                                isArray: false,
                                                isRequired: false,
                                                isNaming: false,
                                                isSecret: false,
                                                isDisplay: false,
                                                relatedTo: item._id
                                            }
                                        }
                                    }).then(({ data: dataPropertyObject, errors }: any) => {
                                        setContextMenuNotVisible();

                                        if (errors) {
                                            for (const er of errors) {
                                                toastError(t(`validations:${er.message}`));
                                            }

                                            return;
                                        }

                                        void addD3Item({
                                            type: RelationshipType.RELATION,
                                            newItemData: {
                                                curve: setCurve(counter),
                                                source: objParent,
                                                target: item,
                                                _id: dataPropertyObject.addNewFieldToNiamObject._id,
                                                fieldType: NiamFieldTypeEnum.Relationship,
                                                isShown: true,
                                                displayName: defaultName,
                                                name: defaultName,
                                                relatedTo: item._id
                                            }
                                        });
                                    });
                                }
                            }))
                        ]
                    },
                    {
                        key: "scriptRightClickItemNodeRelationship",
                        image: "NodeRelationshipIcon",
                        title: t("rightClick:add.nodeRelationship"),
                        type: MenuOptionsType.ADD,
                        nestedMenuOptions: [
                            ...data.objects
                                .filter((f) => objParent._id !== f._id)
                                .map((item): IMenuOption => ({
                                    title: item.displayName,
                                    key: randomKey(),
                                    image: "ObjectIcon",
                                    type: MenuOptionsType.ADD,
                                    onClick: () => {
                                        const { defaultName } = setDefaultName(objParent.name, `R_${item.name}`, objParent.nodeRelationships, item);

                                        void addNewNodeRelationship({
                                            variables: {
                                                serverID: server._id,
                                                name: defaultName,
                                                displayName: defaultName,
                                                objFrom: {
                                                    _id: objParent._id,
                                                    name: objParent.name
                                                },
                                                objTo: {
                                                    _id: item._id,
                                                    name: item.name
                                                }
                                            }
                                        }).then(async ({ data: res, errors }: any) => {
                                            setContextMenuNotVisible();

                                            if (errors) {
                                                for (const err of errors) {
                                                    toastError(t(`validations:${err.message}`));
                                                }

                                                return;
                                            }

                                            const { rel, objFrom, relFrom, relTo, objTo } = res.addNewNodeRelationship;

                                            const nOrbit = await addD3Item({
                                                injectData: {
                                                    objFrom,
                                                    objTo,
                                                    rel
                                                },
                                                type: "nodeRelationship",
                                                newItemData: {
                                                    ...rel,
                                                    vx: 0,
                                                    vy: 0,
                                                    isShown: true
                                                }
                                            });

                                            const nLink = await addD3Item({
                                                injectData: nOrbit,
                                                type: RelationshipType.NODE_RELATION,
                                                newItemData: {
                                                    type: RelationshipType.NODE_RELATION,
                                                    curve: 0,
                                                    source: objFrom._id,
                                                    target: rel._id,
                                                    _id: relFrom._id,
                                                    isShown: true,
                                                    relatedTo: rel._id
                                                }
                                            });

                                            void addD3Item({
                                                injectData: nLink,
                                                type: RelationshipType.NODE_RELATION,
                                                newItemData: {
                                                    type: RelationshipType.NODE_RELATION,
                                                    curve: 0,
                                                    source: rel._id,
                                                    target: objTo._id,
                                                    _id: relTo._id,
                                                    isShown: true,
                                                    relatedTo: objTo._id
                                                }
                                            });
                                        });
                                    }
                                }))
                        ]
                    },
                    {
                        key: "RightClickItemEnum",
                        image: "EnumIcon",
                        title: t("rightClick:item.enum"),
                        type: MenuOptionsType.ADD,
                        nestedMenuOptions: [
                            ...data.enums
                                .filter((f) => {
                                    return !objParent.fields.map((field) => field.relatedTo).includes(f._id)
                                })
                                .map((item): IMenuOption => ({
                                    title: item.displayName,
                                    key: randomKey(),
                                    image: "ObjectIcon",
                                    type: MenuOptionsType.ADD,
                                    onClick: () => {
                                        const { defaultName, counter } = setDefaultName(objParent.name, item.name, objParent.fields, item);

                                        void addNewPropertyToObjectMutation({
                                            variables: {
                                                NiamObjectID: objParent._id,
                                                newNiamFieldInput: {
                                                    fieldType: NiamFieldTypeEnum.Enum,
                                                    name: defaultName,
                                                    displayName: defaultName,
                                                    isArray: false,
                                                    isRequired: false,
                                                    isNaming: false,
                                                    isSecret: false,
                                                    isDisplay: false,
                                                    relatedTo: item._id
                                                }
                                            }
                                        }).then(({ data: dataPropertyObject, errors }: any) => {
                                            setContextMenuNotVisible();

                                            if (errors) {
                                                for (const er of errors) {
                                                    toastError(t(`validations:${er.message}`));
                                                }

                                                return;
                                            }

                                            void addD3Item({
                                                type: RelationshipType.RELATION,
                                                newItemData: {
                                                    curve: setCurve(counter),
                                                    source: item,
                                                    target: objParent,
                                                    _id: dataPropertyObject.addNewFieldToNiamObject._id,
                                                    isShown: true,
                                                    displayName: defaultName,
                                                    name: defaultName,
                                                    relatedTo: item._id,
                                                    fieldType: NiamFieldTypeEnum.NiamEnum,
                                                    type: "enum"
                                                }
                                            });
                                        });
                                    }
                                }))
                        ]
                    },
                    {
                        key: "RightClickAddProperty",
                        image: "FieldIcon",
                        title: t("rightClick:add.property"),
                        type: MenuOptionsType.ADD,
                        nestedMenuOptions: Object.keys(propertyTypes).map((name) => ({
                            title: name,
                            image: name,
                            key: randomKey(),
                            type: MenuOptionsType.ADD,
                            onClick: () => {
                                const { defaultName } = setDefaultName(objParent.name, name, objParent.fields);

                                void addNewPropertyToObjectMutation({
                                    variables: {
                                        NiamObjectID: objParent._id,
                                        newNiamFieldInput: {
                                            fieldType: name,
                                            name: defaultName,
                                            displayName: defaultName,
                                            isArray: false,
                                            isNaming: false,
                                            isSecret: false,
                                            isDisplay: false,
                                            isRequired: false
                                        }
                                    }
                                }).then(({ data: dataPropertyObject, errors }: any) => {
                                    setContextMenuNotVisible();

                                    if (errors) {
                                        for (const er of errors) {
                                            toastError(t(`validations:${er.message}`));
                                        }

                                        return;
                                    }

                                    objParent.fields.push(dataPropertyObject.addNewFieldToNiamObject);

                                    setItemSelected({
                                        data: {
                                            _id: dataPropertyObject.addNewFieldToNiamObject._id,
                                            type: "Property",
                                            parent: {
                                                _id: objParent._id,
                                                __typename: dataPropertyObject.addNewFieldToNiamObject.__typename,
                                                displayName: dataPropertyObject.addNewFieldToNiamObject.displayName,
                                                type: "Object",
                                                x: 0,
                                                y: 0
                                            }
                                        }
                                    });
                                });
                            }
                        }))
                    },
                    {
                        key: "RightClickDeleteObject",
                        image: "DeleteOutline",
                        title: t("rightClick:delete.object"),
                        type: MenuOptionsType.DEL,
                        onClick: () => {
                            showConfirmDeletionPopup({
                                title: t("rightClick:question.object.delete"),
                                onConfirm: deleteObjectHandler,
                                _id: objParent._id
                            });
                        }
                    }
                );
            }

            if (orbit.__typename === NodeTypename.NIAM_INTERFACE && data.interfaces.length > 0) {
                const interParent = data.interfaces.find(item => item._id === orbit._id)

                menuOptions = [
                    {
                        key: "RightClickItemInterface",
                        image: "EnumIcon",
                        title: t("rightClick:item.enum"),
                        type: MenuOptionsType.ADD,
                        nestedMenuOptions: [
                            ...data.enums
                                .filter((f) => {
                                    return !interParent.fields.map((field) => field.relatedTo).includes(f._id)
                                })
                                .map((item): IMenuOption => ({
                                    title: item.displayName,
                                    key: randomKey(),
                                    image: "ObjectIcon",
                                    type: MenuOptionsType.ADD,
                                    onClick: () => {
                                        const { defaultName, counter } = setDefaultName(interParent.name, item.name, interParent.fields, item);

                                        void addNewPropertyToIntefaceMutation({
                                            variables: {
                                                niamInterfaceID: interParent._id,
                                                newNiamFieldInput: {
                                                    fieldType: NiamFieldTypeEnum.Enum,
                                                    name: defaultName,
                                                    displayName: defaultName,
                                                    isArray: false,
                                                    isRequired: false,
                                                    isNaming: false,
                                                    isSecret: false,
                                                    isDisplay: false,
                                                    relatedTo: item._id
                                                }
                                            }
                                        }).then(({ data: dataPropertyInterface, errors }: any) => {
                                            setContextMenuNotVisible();

                                            if (errors) {
                                                for (const er of errors) {
                                                    toastError(t(`validations:${er.message}`));
                                                }

                                                return;
                                            }

                                            void addD3Item({
                                                type: RelationshipType.RELATION,
                                                newItemData: {
                                                    curve: setCurve(counter),
                                                    source: item,
                                                    target: interParent,
                                                    _id: dataPropertyInterface.addNewNiamFieldToNiamInterface._id,
                                                    isShown: true,
                                                    displayName: dataPropertyInterface.addNewNiamFieldToNiamInterface.displayName,
                                                    name: dataPropertyInterface.addNewNiamFieldToNiamInterface.name,
                                                    relatedTo: item._id,
                                                    fieldType: NiamFieldTypeEnum.NiamEnum,
                                                    type: "enum"
                                                }
                                            });
                                        });
                                    }
                                }))
                        ]
                    },
                    {
                        key: "RightClickAddProperty",
                        image: "FieldIcon",
                        title: t("rightClick:add.property"),
                        type: MenuOptionsType.ADD,
                        nestedMenuOptions: Object.keys(propertyTypes).map((name) => ({
                            title: name,
                            image: name,
                            key: randomKey(),
                            type: MenuOptionsType.ADD,
                            onClick: () => {
                                const { defaultName } = setDefaultName(interParent.name, name, interParent.fields);

                                void addNewPropertyToIntefaceMutation({
                                    variables: {
                                        niamInterfaceID: interParent._id,
                                        newNiamFieldInput: {
                                            fieldType: name,
                                            name: defaultName,
                                            displayName: defaultName,
                                            isArray: false,
                                            isNaming: false,
                                            isSecret: false,
                                            isDisplay: false,
                                            isRequired: false
                                        }
                                    }
                                }).then(({ data: dataPropertyInterface, errors }: any) => {
                                    setContextMenuNotVisible();

                                    if (errors) {
                                        for (const er of errors) {
                                            toastError(t(`validations:${er.message}`));
                                        }

                                        return;
                                    }

                                    interParent.fields.push(dataPropertyInterface.addNewNiamFieldToNiamInterface);

                                    setItemSelected({
                                        data: {
                                            _id: dataPropertyInterface.addNewNiamFieldToNiamInterface._id,
                                            type: "Property",
                                            parent: {
                                                _id: interParent._id,
                                                __typename: dataPropertyInterface.addNewNiamFieldToNiamInterface.__typename,
                                                displayName: dataPropertyInterface.addNewNiamFieldToNiamInterface.displayName,
                                                type: "Object",
                                                x: 0,
                                                y: 0
                                            }
                                        }
                                    });
                                });
                            }
                        }))
                    },
                    {
                        key: "RightClickDeleteInterface",
                        image: "DeleteOutline",
                        title: t("rightClick:delete.interface"),
                        type: MenuOptionsType.DEL,
                        onClick: () => {
                            showConfirmDeletionPopup({
                                title: t("rightClick:question.interface.delete"),
                                onConfirm: deleteInterfaceHandler,
                                _id: interParent._id
                            });
                        }
                    }
                ];
            }

            if (orbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP) {
                const nrParent = data.nodeRelationships.find(item => item._id === orbit._id)

                menuOptions = [
                    {
                        key: "RightClickItemInterface",
                        image: "PropertyIcon",
                        title: t("rightClick:add.property"),
                        type: MenuOptionsType.ADD,
                        nestedMenuOptions: Object.keys(propertyTypes).map((name) => ({
                            title: name,
                            image: name,
                            key: randomKey(),
                            type: MenuOptionsType.ADD,
                            onClick: () => {
                                const { defaultName } = setDefaultName(nrParent.name, name, nrParent.fields);

                                void addFieldToNodeRelationship({
                                    variables: {
                                        nodeRelationshipID: nrParent._id,
                                        newNiamFieldInput: {
                                            fieldType: name,
                                            name: defaultName,
                                            displayName: defaultName,
                                            isArray: false,
                                            isNaming: false,
                                            isSecret: false,
                                            isDisplay: false,
                                            isRequired: false
                                        }
                                    }
                                }).then(({ data: dataPropertyNR, errors }: any) => {
                                    setContextMenuNotVisible();

                                    if (errors) {
                                        for (const er of errors) {
                                            toastError(t(`validations:${er.message}`));
                                        }

                                        return;
                                    }

                                    nrParent.fields.push(dataPropertyNR.addNewFieldToNiamNodeRelationship);

                                    setItemSelected({
                                        data: {
                                            _id: dataPropertyNR.addNewFieldToNiamNodeRelationship._id,
                                            type: "Property",
                                            parent: {
                                                _id: nrParent._id,
                                                __typename: dataPropertyNR.addNewFieldToNiamNodeRelationship.__typename,
                                                displayName: dataPropertyNR.addNewFieldToNiamNodeRelationship.displayName,
                                                type: "NodeRelationship",
                                                x: 0,
                                                y: 0
                                            }
                                        }
                                    });
                                });
                            }
                        }))
                    },
                    {
                        key: "RightClickDeleteNodeRelationship",
                        image: "DeleteOutline",
                        title: t("rightClick:delete.NodeRelationship"),
                        type: MenuOptionsType.DEL,
                        onClick: () => {
                            showConfirmDeletionPopup({
                                title: t("rightClick:question.NodeRelationship.delete"),
                                onConfirm: deleteNodeRelationshipHandler,
                                _id: nrParent._id
                            });
                        }
                    }
                ];
            }

            if (orbit.__typename === NodeTypename.NIAM_SCRIPT) {
                const scriptParent = data.scripts.find(item => item._id === orbit._id)

                menuOptions = [
                    {
                        key: "scriptRightClickItemRelationship",
                        image: "PropertyIcon",
                        title: t("rightClick:add.relationship"),
                        type: MenuOptionsType.ADD,
                        nestedMenuOptions: [
                            ...data.objects.map((item): IMenuOption => ({
                                title: item.displayName,
                                key: randomKey(),
                                image: "ObjectIcon",
                                type: MenuOptionsType.ADD,
                                onClick: () => {
                                    const { defaultName } = setDefaultName(scriptParent.name, item.name, scriptParent.relationships, item);

                                    let counter = 1;
                                    scriptParent.relationships.forEach((rel) => {
                                        if (rel.relatedTo === item._id) {
                                            counter++;
                                        }
                                    });

                                    void addNewPropertyToScriptMutation({
                                        variables: {
                                            scriptID: scriptParent._id,
                                            eventType: null,
                                            name: defaultName,
                                            displayName: defaultName,
                                            relatedTo: item._id
                                        }
                                    }).then(({ data: dataPropertyScript, errors }: any) => {
                                        setContextMenuNotVisible();

                                        if (errors) {
                                            for (const er of errors) {
                                                toastError(t(`validations:${er.message}`));
                                            }

                                            return;
                                        }

                                        void addD3Item({
                                            type: RelationshipType.RELATION,
                                            newItemData: {
                                                curve: setCurve(counter),
                                                source: scriptParent,
                                                target: item,
                                                _id: dataPropertyScript.addNewRelationshipToNiamScript._id,
                                                fieldType: NiamFieldTypeEnum.Relationship,
                                                isShown: true,
                                                displayName: defaultName,
                                                name: defaultName,
                                                relatedTo: item._id
                                            }
                                        });
                                    });
                                }
                            }))
                        ]
                    },
                    {
                        key: "scriptRightClickItemNodeRelationship",
                        image: "NodeRelationshipIcon",
                        title: t("rightClick:add.nodeRelationship"),
                        type: MenuOptionsType.ADD,
                        nestedMenuOptions: [
                            ...data.nodeRelationships.map((item): IMenuOption => ({
                                title: item.displayName,
                                key: randomKey(),
                                image: "ObjectIcon",
                                type: MenuOptionsType.ADD,
                                onClick: () => {
                                    const { defaultName } = setDefaultName(scriptParent.name, item.name, scriptParent.relationships, item);

                                    let counter = 1;
                                    scriptParent.relationships.forEach((rel) => {
                                        if (rel.relatedTo === item._id) {
                                            counter++;
                                        }
                                    });

                                    void addNewPropertyToScriptMutation({
                                        variables: {
                                            scriptID: scriptParent._id,
                                            eventType: null,
                                            name: defaultName,
                                            displayName: defaultName,
                                            relatedTo: item._id
                                        }
                                    }).then(({ data: res, errors }: any) => {
                                        setContextMenuNotVisible();

                                        if (errors) {
                                            for (const err of errors) {
                                                toastError(t(`validations:${err.message}`));
                                            }
                                            return;
                                        }

                                        void addD3Item({
                                            type: RelationshipType.RELATION,
                                            newItemData: {
                                                curve: setCurve(counter),
                                                source: scriptParent,
                                                target: item,
                                                _id: res.addNewRelationshipToNiamScript._id,
                                                fieldType: NiamFieldTypeEnum.Relationship,
                                                isShown: true,
                                                displayName: defaultName,
                                                name: defaultName,
                                                relatedTo: item._id
                                            }
                                        });
                                    });
                                }
                            }))
                        ]
                    },
                    {
                        key: "RightClickDeleteScript",
                        image: "DeleteOutline",
                        title: t("rightClick:delete.script"),
                        type: MenuOptionsType.DEL,
                        onClick: () => {
                            showConfirmDeletionPopup({
                                title: t("rightClick:question.script.delete"),
                                onConfirm: deleteScriptHandler,
                                _id: scriptParent._id
                            });
                        }
                    }
                ];
            }

            if (orbit.__typename === NodeTypename.NIAM_CUSTOM_MUTATION) {
                menuOptions = [
                    {
                        key: "RightClickDeleteCustomMutation",
                        image: "DeleteOutline",
                        title: t("rightClick:delete.CustomMutation"),
                        type: MenuOptionsType.DEL,
                        onClick: () => {
                            showConfirmDeletionPopup({
                                title: t("rightClick:question.CustomMutation.delete"),
                                onConfirm: deleteCustomMutationHandler,
                                _id: orbit._id
                            });
                        }
                    }
                ];
            }

            if (orbit.__typename === NodeTypename.NIAM_TAG) {
                menuOptions = [
                    {
                        key: "RightClickDeleteTag",
                        image: "DeleteOutline",
                        title: t("rightClick:delete.tag"),
                        type: MenuOptionsType.DEL,
                        onClick: () => {
                            showConfirmDeletionPopup({
                                title: t("rightClick:question.tag.delete"),
                                onConfirm: deleteTagHandler,
                                _id: orbit._id
                            });
                        }
                    }
                ];
            }

            if (orbit.__typename === NodeTypename.NIAM_ENUM) {
                menuOptions = [
                    {
                        key: "RightClickDeleteEnum",
                        image: "DeleteOutline",
                        title: t("rightClick:delete.enum"),
                        type: MenuOptionsType.DEL,
                        onClick: () => {
                            showConfirmDeletionPopup({
                                title: t("rightClick:question.enum.delete"),
                                onConfirm: deleteEnumHandler,
                                _id: orbit._id
                            });
                        }
                    }
                ];
            }
        } else {

            if (!orbit.isResource && [NodeTypename.NIAM_OBJECT, NodeTypename.NIAM_CUSTOM_MUTATION].includes(orbit.__typename as NodeTypename)) {
                menuOptions.push(
                    {
                        key: "RightClickAddAuthResource",
                        image: "PolicyResourceIcon",
                        title: `${t("rightClick:add.auth.resource")}`,
                        type: MenuOptionsType.ADD,
                        onClick: () => {
                            if (isEmpty(orbit.isResource)) {
                                const targetData = e.target["__data__"];
                                addNewResource(targetData)
                                setContextMenuNotVisible();
                            } else {
                                toastWarning(t(`validations:isResource.already.exists`));
                            }
                        }
                    }
                )
            }

            if ([NodeTypename.NIAM_OBJECT].includes(orbit.__typename as NodeTypename))
            {
                const alreadyExists = data.objects.find(obj => obj.isSubject)

                if (!alreadyExists && !orbit.isSubject) {
                    menuOptions.push(
                        {
                            key: "RightClickAddAuthSubject",
                            image: "PolicySubjectIcon",
                            title: `${t("rightClick:add.auth.subject")}`,
                            type: MenuOptionsType.ADD,
                            onClick: () => {
                                if (isEmpty(orbit.isSubject)) {
                                    const targetData = e.target["__data__"];
                                    addNewSubject(targetData)
                                    setContextMenuNotVisible();
                                } else {
                                    toastWarning(t(`validations:isSubject.already.exists`));
                                }
                            }
                        }
                    )
                }

                if (!orbit.isAction) {
                    menuOptions.push(
                        {
                            key: "RightClickAddAuthAction",
                            image: "PolicyActionIcon",
                            title: `${t("rightClick:add.auth.action")}`,
                            type: MenuOptionsType.ADD,
                            onClick: () => {
                                if (isEmpty(orbit.isAction)) {
                                    const targetData = e.target["__data__"];
                                    addNewAction(targetData)
                                    setContextMenuNotVisible();
                                } else {
                                    toastWarning(t(`validations:isAction.already.exists`));
                                }
                            }
                        }
                    )
                }

                if (!orbit.isVC) {
                    menuOptions.push(
                        {
                            key: "RightClickAddAuthVC",
                            image: "PolicyVCIcon",
                            title: `${t("rightClick:add.auth.vc")}`,
                            type: MenuOptionsType.ADD,
                            onClick: () => {
                                if (isEmpty(orbit.isVC)) {
                                    const targetData = e.target["__data__"];
                                    addNewVC(targetData)
                                    setContextMenuNotVisible();
                                } else {
                                    toastWarning(t(`validations:isVC.already.exists`));
                                }
                            }
                        }
                    )
                }

                if (!orbit.isHolder) {
                    menuOptions.push(
                        {
                            key: "RightClickAddAuthHolder",
                            image: "PolicyHolderIcon",
                            title: `${t("rightClick:add.auth.holder")}`,
                            type: MenuOptionsType.ADD,
                            onClick: () => {
                                if (isEmpty(orbit.isHolder)) {
                                    const targetData = e.target["__data__"];
                                    if (checkIfAlreadyExistsVCs(targetData)) {
                                        toastWarning(t(`validations:vcs.already.exists`));
                                    } else {
                                        addNewHolder(targetData)
                                    }
                                    setContextMenuNotVisible();
                                } else {
                                    toastWarning(t(`validations:isHolder.already.exists`));
                                }
                            }
                        }
                    )
                }

                if (!orbit.isIssuer) {
                    menuOptions.push(
                        {
                            key: "RightClickAddAuthIssuer",
                            image: "PolicyIssuerIcon",
                            title: `${t("rightClick:add.auth.issuer")}`,
                            type: MenuOptionsType.ADD,
                            onClick: () => {
                                if (isEmpty(orbit.isIssuer)) {
                                    const targetData = e.target["__data__"];
                                    if (checkIfAlreadyExistsVCs(targetData))
                                    {
                                        toastWarning(t(`validations:vcs.already.exists`));
                                    } else
                                    {
                                        addNewIssuer(targetData)
                                    }
                                    setContextMenuNotVisible();
                                } else {
                                    toastWarning(t(`validations:isIssuer.already.exists`));
                                }
                            }
                        }
                    )
                }

                if (!orbit.isClaim) {
                    menuOptions.push(
                        {
                            key: "RightClickAddAuthClaim",
                            image: "PolicyClaimIcon",
                            title: `${t("rightClick:add.auth.claim")}`,
                            type: MenuOptionsType.ADD,
                            onClick: () => {
                                if (isEmpty(orbit.isClaim)) {
                                    const targetData = e.target["__data__"];
                                    if (checkIfAlreadyExistsVCs(targetData))
                                    {
                                        toastWarning(t(`validations:vcs.already.exists`));
                                    } else
                                    {
                                        addNewClaim(targetData)
                                    }
                                    setContextMenuNotVisible();
                                } else {
                                    toastWarning(t(`validations:isClaim.already.exists`));
                                }
                            }
                        }
                    )
                }
            }

            if ((orbit.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(orbit.__typename as NodeTypename)) && isEmpty(orbit.simplePath)) {
                menuOptions.push(
                    {
                        key: "RightClickAddAuthPath",
                        image: "PolicyArrowIcon",
                        title: `${t("rightClick:add.auth.path")}`,
                        type: MenuOptionsType.ADD,
                        onClick: () =>
                        {
                            if (isEmpty(orbit.simplePath))
                            {
                                const targetData = e.target["__data__"];
                                addNewPath(targetData)
                                setContextMenuNotVisible();
                            } else
                            {
                                toastWarning(t(`validations:path.already.exists`));
                            }
                        }
                    }
                )
            }

            if ((orbit.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(orbit.__typename as NodeTypename)) && isEmpty(orbit.simplePathCreate)) {
                menuOptions.push(
                    {
                        key: "RightClickAddAuthPathCreate",
                        image: "PolicyArrowIcon",
                        title: `${t("rightClick:add.auth.pathCreate")}`,
                        type: MenuOptionsType.ADD,
                        onClick: () =>
                        {
                            if(isNotEmpty(orbit.simplePath)){
                                toastWarning(t(`validations:path.already.exists`));
                            } else if (isEmpty(orbit.simplePathCreate)) {
                                const targetData = e.target["__data__"];
                                addNewPathCreate(targetData)
                                setContextMenuNotVisible();
                            } else
                            {
                                toastWarning(t(`validations:path.already.exists`));
                            }
                        }
                    }
                )
            }

            if ((orbit.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(orbit.__typename as NodeTypename)) && isEmpty(orbit.simplePathRead)) {
                menuOptions.push(
                    {
                        key: "RightClickAddAuthPathRead",
                        image: "PolicyArrowIcon",
                        title: `${t("rightClick:add.auth.pathRead")}`,
                        type: MenuOptionsType.ADD,
                        onClick: () =>
                        {
                            if(isNotEmpty(orbit.simplePath)){
                                toastWarning(t(`validations:path.already.exists`));
                            } else if (isEmpty(orbit.simplePathRead))
                            {
                                const targetData = e.target["__data__"];
                                addNewPathRead(targetData)
                                setContextMenuNotVisible();
                            } else
                            {
                                toastWarning(t(`validations:path.already.exists`));
                            }
                        }
                    }
                )
            }

            if ((orbit.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(orbit.__typename as NodeTypename)) && isEmpty(orbit.simplePathUpdate)) {
                menuOptions.push(
                    {
                        key: "RightClickAddAuthPathUpdate",
                        image: "PolicyArrowIcon",
                        title: `${t("rightClick:add.auth.pathUpdate")}`,
                        type: MenuOptionsType.ADD,
                        onClick: () =>
                        {
                            if(isNotEmpty(orbit.simplePath)){
                                toastWarning(t(`validations:path.already.exists`));

                            } else if (isEmpty(orbit.simplePathUpdate))
                            {
                                const targetData = e.target["__data__"];
                                addNewPathUpdate(targetData)
                                setContextMenuNotVisible();
                            } else
                            {
                                toastWarning(t(`validations:path.already.exists`));
                            }
                        }
                    }
                )
            }

            if ((orbit.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(orbit.__typename as NodeTypename)) && isEmpty(orbit.simplePathDelete)) {
                menuOptions.push(
                    {
                        key: "RightClickAddAuthPathDelete",
                        image: "PolicyArrowIcon",
                        title: `${t("rightClick:add.auth.pathDelete")}`,
                        type: MenuOptionsType.ADD,
                        onClick: () =>
                        {
                            if(isNotEmpty(orbit.simplePath)){
                                toastWarning(t(`validations:path.already.exists`));

                            } else if (isEmpty(orbit.simplePathDelete))
                            {
                                const targetData = e.target["__data__"];
                                addNewPathDelete(targetData)
                                setContextMenuNotVisible();
                            } else
                            {
                                toastWarning(t(`validations:path.already.exists`));
                            }
                        }
                    }
                )
            }

            if ((orbit.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(orbit.__typename as NodeTypename)) && isEmpty(orbit.isAction)) {
                menuOptions.push(
                    {
                        key: "RightClickAddActionToRelationship",
                        image: "PolicyActionIcon",
                        title: `${t("rightClick:add.auth.action")}`,
                        type: MenuOptionsType.ADD,
                        onClick: () =>
                        {
                            if (isEmpty(orbit.isAction))
                            {
                                const targetData = e.target["__data__"];
                                addNewActionRelationship(targetData)
                                setContextMenuNotVisible();
                            } else
                            {
                                toastWarning(t(`validations:isAction.already.exists`));
                            }
                        }
                    }
                )
            }

            if ((orbit.type === RelationshipType.RELATION || [NodeTypename.NIAM_NODE_RELATIONSHIP].includes(orbit.__typename as NodeTypename)) && isEmpty(orbit.isVC)) {
                menuOptions.push(
                    {
                        key: "RightClickAddVCToRelationship",
                        image: "PolicyVCIcon",
                        title: `${t("rightClick:add.auth.vc")}`,
                        type: MenuOptionsType.ADD,
                        onClick: () =>
                        {
                            if (isEmpty(orbit.isVC))
                            {
                                const targetData = e.target["__data__"];
                                addNewVCRelationship(targetData)
                                setContextMenuNotVisible();
                            } else
                            {
                                toastWarning(t(`validations:isVC.already.exists`));
                            }
                        }
                    }
                )
            }

            if(orbit.type === RelationshipType.PATHS || orbit.simplePath  || orbit.simplePathCreate || orbit.simplePathRead || orbit.simplePathUpdate || orbit.simplePathDelete || orbit.isResource || orbit.isSubject || orbit.isAction || orbit.isVC) {
                if (isNotEmpty(orbit.simplePath) && (orbit.type === RelationshipType.RELATION || orbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP)) {
                    menuOptions.push(
                        {
                            key: "RightClickDeleteAuthSimplePath",
                            image: "DeleteOutline",
                            title: `${t("rightClick:delete.auth.simple.path")}`,
                            type: MenuOptionsType.DEL,
                            onClick: () => {
                                showConfirmDeletionPopup({
                                    title: t("rightClick:question.auth.delete"),
                                    onConfirm: deleteAuthSimplePath,
                                    _id: orbit.simplePath._id,
                                    relatedTo: orbit.simplePath.relatedTo
                                });
                            }
                        }
                    )
                }

                if (isNotEmpty(orbit.simplePathCreate) && (orbit.type === RelationshipType.RELATION || orbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP)) {
                    menuOptions.push(
                        {
                            key: "RightClickDeleteAuthSimplePathCreate",
                            image: "DeleteOutline",
                            title: `${t("rightClick:delete.auth.simple.pathCreate")}`,
                            type: MenuOptionsType.DEL,
                            onClick: () => {
                                showConfirmDeletionPopup({
                                    title: t("rightClick:question.auth.delete"),
                                    onConfirm: deleteAuthSimplePathCreate,
                                    _id: orbit.simplePathCreate._id,
                                    relatedTo: orbit.simplePathCreate.relatedTo
                                });
                            }
                        }
                    )
                }

                if (isNotEmpty(orbit.simplePathRead) && (orbit.type === RelationshipType.RELATION || orbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP)) {
                    menuOptions.push(
                        {
                            key: "RightClickDeleteAuthSimplePathRead",
                            image: "DeleteOutline",
                            title: `${t("rightClick:delete.auth.simple.pathRead")}`,
                            type: MenuOptionsType.DEL,
                            onClick: () => {
                                showConfirmDeletionPopup({
                                    title: t("rightClick:question.auth.delete"),
                                    onConfirm: deleteAuthSimplePathRead,
                                    _id: orbit.simplePathRead._id,
                                    relatedTo: orbit.simplePathRead.relatedTo
                                });
                            }
                        }
                    )
                }

                if (isNotEmpty(orbit.simplePathUpdate) && (orbit.type === RelationshipType.RELATION || orbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP)) {
                    menuOptions.push(
                        {
                            key: "RightClickDeleteAuthSimplePathUpdate",
                            image: "DeleteOutline",
                            title: `${t("rightClick:delete.auth.simple.pathUpdate")}`,
                            type: MenuOptionsType.DEL,
                            onClick: () => {
                                showConfirmDeletionPopup({
                                    title: t("rightClick:question.auth.delete"),
                                    onConfirm: deleteAuthSimplePathUpdate,
                                    _id: orbit.simplePathUpdate._id,
                                    relatedTo: orbit.simplePathUpdate.relatedTo
                                });
                            }
                        }
                    )
                }

                if (isNotEmpty(orbit.simplePathDelete) && (orbit.type === RelationshipType.RELATION || orbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP)) {
                    menuOptions.push(
                        {
                            key: "RightClickDeleteAuthSimplePathDelete",
                            image: "DeleteOutline",
                            title: `${t("rightClick:delete.auth.simple.pathDelete")}`,
                            type: MenuOptionsType.DEL,
                            onClick: () => {
                                showConfirmDeletionPopup({
                                    title: t("rightClick:question.auth.delete"),
                                    onConfirm: deleteAuthSimplePathDelete,
                                    _id: orbit.simplePathDelete._id,
                                    relatedTo: orbit.simplePathDelete.relatedTo
                                });
                            }
                        }
                    )
                }

                if (isNotEmpty(orbit.isAction) && (orbit.type === RelationshipType.RELATION || orbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP)) {
                    menuOptions.push(
                        {
                            key: "RightClickDeleteActionRalationship",
                            image: "DeleteOutline",
                            title: `${t("rightClick:delete.auth.action")}`,
                            type: MenuOptionsType.DEL,
                            onClick: () => {
                                showConfirmDeletionPopup({
                                    title: t("rightClick:question.auth.delete"),
                                    onConfirm: deleteAuthActionRelationship,
                                    _id: orbit._id,
                                });
                            }
                        }
                    )
                }

                if (isNotEmpty(orbit.isVC) && (orbit.type === RelationshipType.RELATION || orbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP)) {
                    menuOptions.push(
                        {
                            key: "RightClickDeleteVCRalationship",
                            image: "DeleteOutline",
                            title: `${t("rightClick:delete.auth.vc")}`,
                            type: MenuOptionsType.DEL,
                            onClick: () => {
                                showConfirmDeletionPopup({
                                    title: t("rightClick:question.auth.delete"),
                                    onConfirm: deleteAuthVCRelationship,
                                    _id: orbit._id,
                                });
                            }
                        }
                    )
                }

                if (orbit.type === RelationshipType.PATHS) {
                    menuOptions.push(
                        {
                            key: "RightClickDeleteAuthPaths",
                            image: "DeleteOutline",
                            title: `${t("rightClick:delete.auth.paths")}`,
                            type: MenuOptionsType.DEL,
                            onClick: () => {
                                showConfirmDeletionPopup({
                                    title: t("rightClick:question.auth.delete"),
                                    onConfirm: deleteAuthPaths,
                                    _id: orbit._id,
                                    relatedTo: orbit._id
                                });
                            }
                        }
                    )
                }

                if (orbit.isResource) {
                    menuOptions.push(
                        {
                            key: "RightClickDeleteAuthResource",
                            image: "DeleteOutline",
                            title: `${t("rightClick:delete.auth.resource")}`,
                            type: MenuOptionsType.DEL,
                            onClick: () => {
                                showConfirmDeletionPopup({
                                    title: t("rightClick:question.auth.delete"),
                                    onConfirm: deleteAuthResource,
                                    _id: orbit._id,
                                    relatedTo: orbit._id
                                });
                            }
                        }
                    )
                }

                if (orbit.isAction && !(orbit.type === RelationshipType.RELATION || orbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP)) {
                    menuOptions.push(
                        {
                            key: "RightClickDeleteAuthAction",
                            image: "DeleteOutline",
                            title: `${t("rightClick:delete.auth.action")}`,
                            type: MenuOptionsType.DEL,
                            onClick: () => {
                                showConfirmDeletionPopup({
                                    title: t("rightClick:question.auth.delete"),
                                    onConfirm: deleteAuthAction,
                                    _id: orbit._id,
                                    relatedTo: orbit._id
                                });
                            }
                        }
                    )
                }

                if (orbit.isVC && !(orbit.type === RelationshipType.RELATION || orbit.__typename === NodeTypename.NIAM_NODE_RELATIONSHIP)) {
                    menuOptions.push({
                        key: "RightClickDeleteAuthVC",
                        image: "DeleteOutline",
                        title: `${t("rightClick:delete.auth.vc")}`,
                        type: MenuOptionsType.DEL,
                        onClick: () => {
                            showConfirmDeletionPopup({
                                title: t("rightClick:question.auth.delete"),
                                onConfirm: deleteAuthVC,
                                _id: orbit._id,
                                relatedTo: orbit._id
                            });
                        }
                    })
                }

                if (orbit.isHolder) {
                    menuOptions.push({
                        key: "RightClickDeleteAuthHolder",
                        image: "DeleteOutline",
                        title: `${t("rightClick:delete.auth.holder")}`,
                        type: MenuOptionsType.DEL,
                        onClick: () => {
                            showConfirmDeletionPopup({
                                title: t("rightClick:question.auth.delete"),
                                onConfirm: deleteAuthHolder,
                                _id: orbit._id,
                                relatedTo: orbit._id
                            });
                        }
                    })
                }

                if (orbit.isIssuer) {
                    menuOptions.push({
                        key: "RightClickDeleteAuthIssuer",
                        image: "DeleteOutline",
                        title: `${t("rightClick:delete.auth.issuer")}`,
                        type: MenuOptionsType.DEL,
                        onClick: () => {
                            showConfirmDeletionPopup({
                                title: t("rightClick:question.auth.delete"),
                                onConfirm: deleteAuthIssuer,
                                _id: orbit._id,
                                relatedTo: orbit._id
                            });
                        }
                    })
                }

                if (orbit.isClaim) {
                    menuOptions.push({
                        key: "RightClickDeleteAuthClaim",
                        image: "DeleteOutline",
                        title: `${t("rightClick:delete.auth.claim")}`,
                        type: MenuOptionsType.DEL,
                        onClick: () => {
                            showConfirmDeletionPopup({
                                title: t("rightClick:question.auth.delete"),
                                onConfirm: deleteAuthClaim,
                                _id: orbit._id,
                                relatedTo: orbit._id
                            });
                        }
                    })
                }

                if (orbit.isSubject) {
                    menuOptions.push(
                        {
                            key: "RightClickDeleteAuthSubject",
                            image: "DeleteOutline",
                            title: `${t("rightClick:delete.auth.subject")}`,
                            type: MenuOptionsType.DEL,
                            onClick: () => {
                                showConfirmDeletionPopup({
                                    title: t("rightClick:question.auth.delete"),
                                    onConfirm: deleteAuthSubject,
                                    _id: orbit._id,
                                    relatedTo: orbit._id
                                });
                            }
                        }
                    )
                }
            }
        }

        setItemSelected(null);

        if (menuOptions.length > 0) {
            contextMenuState.setContextMenuState({
                visibility: true,
                x: e.pageX,
                y: e.pageY,
                menuOptions,
                title: t("rightClick:manage.item")
            });
        }
    };

    useEffect(() => {
        if (d3Data.g) {
            d3Data.g.selectAll(".orbit").on("contextmenu", contextMenuCB);
            d3Data.g.selectAll(".link").on("contextmenu", contextMenuCB);
        }

    }, [data, d3Data.g]);
};
