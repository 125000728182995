import { gql } from "@apollo/client";

export const D3_IMPLEMENT_INTERFACE = gql`
  mutation d3ImplementInterface($niamInterfaceID: ID!, $niamObjectID: ID!, $serverID: ID!) {
      addNiamInterfaceToNiamObject(input: { niamInterfaceID: $niamInterfaceID, niamObjectID: $niamObjectID, serverID: $serverID }) {
          _id
          objectID
          interfaceID
      }
  }
`;

export const D3_ADD_PROPERTY_TO_OBJECT = gql`
  mutation d3AddPropertyToObject($NiamObjectID: ID!, $newNiamFieldInput: NiamFieldInput!) {
      addNewFieldToNiamObject(input: { NiamObjectID: $NiamObjectID, newNiamFieldInput: $newNiamFieldInput }) {
          _id
          name
          displayName
          relatedTo
      }
  }
`;

export const D3_ADD_PATH_OBJECT_TO_CUSTOM_MUTATION = gql`
  mutation addNewPathObjectToCustomMutation($objectID: ID!, $targetID: ID!) {
    addNewPathObjectToCustomMutation(input: { objectID: $objectID, targetID: $targetID }) {
          _id
          targetID
      }
  }
`;

export const DELETE_PATH_OBJECT_TO_CUSTOM_MUTATION = gql`
  mutation deletePathObjectToCustomMutation($_id: ID!) {
    deletePathObjectToCustomMutation(input: { _id: $_id })
  }
`;

export const D3_ADD_NODE_RELATIONSHIP = gql`
  mutation addNewNodeRelationship(
      $name: String!
      $displayName: String
      $serverID: ID!
      $objFrom: NodeRelationshipNiamObjectInput!
      $objTo: NodeRelationshipNiamObjectInput!
  ) {
      addNewNodeRelationship(
          input: { name: $name, displayName: $displayName, objFrom: $objFrom, objTo: $objTo, serverID: $serverID }
      ) {
          objFrom {
              _id
              name
              displayName
          }
          objTo {
              _id
              name
              displayName
          }
          rel {
              _id
              name
              displayName
              fields {
                  _id
                  displayName
                  name
                  fieldType
                  relatedTo
              }
          }
          relFrom {
              _id
              name
              displayName
          }
          relTo {
              _id
              name
              displayName
          }
      }
  }
`;

export const D3_ADD_PROPERTY_TO_SCRIPT = gql`
  mutation d3AddPropertyToScript(
      $scriptID: ID!
      $eventType: NiamRelationshipTypeEnum
      $name: String!
      $displayName: String
      $relatedTo: ID!
  ) {
      addNewRelationshipToNiamScript(
          input: {
              scriptID: $scriptID
              eventType: $eventType
              name: $name
              displayName: $displayName
              relatedTo: $relatedTo
          }
      ) {
          _id
          name
          displayName
          eventType
          relatedTo
          fieldType
      }
  }
`;

export const D3_ADD_PROPERTY_TO_INTERFACE = gql`
  mutation d3AddPropertyToInterface($niamInterfaceID: ID!, $newNiamFieldInput: NiamFieldInput!) {
      addNewNiamFieldToNiamInterface(
          input: { niamInterfaceID: $niamInterfaceID, newNiamFieldInput: $newNiamFieldInput }
      ) {
          _id
          displayName
          name
          fieldType
          relatedTo
      }
  }
`;

export const ADD_NEW_PATH = gql`
  mutation addSimplePathAuthZLabelToRelationship($targetID: ID!) {
    addSimplePathAuthZLabelToRelationship(input: { targetID: $targetID }) {
        _id
    }
  }
`;

export const ADD_NEW_PATH_CREATE = gql`
  mutation addSimplePathCreateAuthZLabelToRelationship($targetID: ID!) {
    addSimplePathCreateAuthZLabelToRelationship(input: { targetID: $targetID }) {
        _id
    }
  }
`;

export const ADD_NEW_PATH_READ = gql`
  mutation addSimplePathReadAuthZLabelToRelationship($targetID: ID!) {
    addSimplePathReadAuthZLabelToRelationship(input: { targetID: $targetID }) {
        _id
    }
  }
`;

export const ADD_NEW_PATH_UPDATE = gql`
  mutation addSimplePathUpdateAuthZLabelToRelationship($targetID: ID!) {
    addSimplePathUpdateAuthZLabelToRelationship(input: { targetID: $targetID }) {
        _id
    }
  }
`;

export const ADD_NEW_PATH_DELETE = gql`
  mutation addSimplePathDeleteAuthZLabelToRelationship($targetID: ID!) {
    addSimplePathDeleteAuthZLabelToRelationship(input: { targetID: $targetID }) {
        _id
    }
  }
`;

export const REMOVE_SIMPLE_PATH = gql`
  mutation removeSimplePathAuthZLabelFromRelationship($_id: ID!) {
    removeSimplePathAuthZLabelFromRelationship(input: { _id: $_id })
  }
`;

export const REMOVE_SIMPLE_PATH_CREATE = gql`
  mutation removeSimplePathCreateAuthZLabelFromRelationship($_id: ID!) {
    removeSimplePathCreateAuthZLabelFromRelationship(input: { _id: $_id })
  }
`;

export const REMOVE_SIMPLE_PATH_READ = gql`
  mutation removeSimplePathReadAuthZLabelFromRelationship($_id: ID!) {
    removeSimplePathReadAuthZLabelFromRelationship(input: { _id: $_id })
  }
`;

export const REMOVE_SIMPLE_PATH_UPDATE = gql`
  mutation removeSimplePathUpdateAuthZLabelFromRelationship($_id: ID!) {
    removeSimplePathUpdateAuthZLabelFromRelationship(input: { _id: $_id })
  }
`;

export const REMOVE_SIMPLE_PATH_DELETE = gql`
  mutation removeSimplePathDeleteAuthZLabelFromRelationship($_id: ID!) {
    removeSimplePathDeleteAuthZLabelFromRelationship(input: { _id: $_id })
  }
`;

export const ADD_NEW_RESOURCE = gql`
  mutation addObjectAuthZLabelToNode($targetID: ID!) {
    addObjectAuthZLabelToNode(input: { targetID: $targetID }) {
        ... on NiamObject {
            _id
        }
    }
  }
`;

export const REMOVE_RESOURCE = gql`
  mutation removeObjectAuthZLabelFromNode($targetID: ID!) {
    removeObjectAuthZLabelFromNode(input: { targetID: $targetID })
  }
`;

export const ADD_NEW_SUBJECT = gql`
  mutation addSubjectAuthZLabelToNode($targetID: ID!) {
    addSubjectAuthZLabelToNode(input: { targetID: $targetID }) {
        ... on NiamObject {
            _id
        }
    }
  }
`;

export const REMOVE_SUBJECT = gql`
  mutation removeSubjectAuthZLabelFromNode($targetID: ID!) {
    removeSubjectAuthZLabelFromNode(input: { targetID: $targetID })
  }
`;

export const ADD_NEW_ACTION = gql`
  mutation addActionAuthZLabelToNode($targetID: ID!) {
    addActionAuthZLabelToNode(input: { targetID: $targetID }) {
        ... on NiamObject {
            _id
        }
    }
  }
`;

export const ADD_NEW_VC = gql`
  mutation addVCAuthZLabelToNode($targetID: ID!) {
    addVCAuthZLabelToNode(input: { targetID: $targetID }) {
        ... on NiamObject {
            _id
        }
    }
  }
`;

export const ADD_NEW_HOLDER = gql`
  mutation addHolderAuthZLabelToNode($targetID: ID!) {
    addHolderAuthZLabelToNode(input: { targetID: $targetID }) {
        ... on NiamObject {
            _id
        }
    }
  }
`;

export const ADD_NEW_ISSUER = gql`
  mutation addIssuerAuthZLabelToNode($targetID: ID!) {
    addIssuerAuthZLabelToNode(input: { targetID: $targetID }) {
        ... on NiamObject {
            _id
        }
    }
  }
`;

export const ADD_NEW_CLAIM = gql`
  mutation addClaimAuthZLabelToNode($targetID: ID!) {
    addClaimAuthZLabelToNode(input: { targetID: $targetID }) {
        ... on NiamObject {
            _id
        }
    }
  }
`;

export const ADD_NEW_VC_RELATIONSHIP = gql`
  mutation addVCAuthZLabelToRelationship($targetID: ID!) {
    addVCAuthZLabelToRelationship(input: { targetID: $targetID }) {
        _id
    }
  }
`;

export const REMOVE_ACTION = gql`
  mutation removeActionAuthZLabelFromNode($targetID: ID!) {
    removeActionAuthZLabelFromNode(input: { targetID: $targetID })
  }
`;

export const REMOVE_VC = gql`
  mutation removeVCAuthZLabelFromNode($targetID: ID!) {
    removeVCAuthZLabelFromNode(input: { targetID: $targetID })
  }
`;

export const REMOVE_HOLDER = gql`
  mutation removeHolderAuthZLabelFromNode($targetID: ID!) {
    removeHolderAuthZLabelFromNode(input: { targetID: $targetID })
  }
`;

export const REMOVE_ISSUER = gql`
  mutation removeIssuerAuthZLabelFromNode($targetID: ID!) {
    removeIssuerAuthZLabelFromNode(input: { targetID: $targetID })
  }
`;

export const REMOVE_CLAIM = gql`
  mutation removeClaimAuthZLabelFromNode($targetID: ID!) {
    removeClaimAuthZLabelFromNode(input: { targetID: $targetID })
  }
`;

export const ADD_NEW_ACTION_RELATIONSHIP = gql`
  mutation addActionAuthZLabelToRelationship($targetID: ID!) {
    addActionAuthZLabelToRelationship(input: { targetID: $targetID }) {
        _id
    }
  }
`;

export const REMOVE_ACTION_RELATIONSHIP = gql`
  mutation removeActionAuthZLabelFromRelationship($targetID: ID!) {
    removeActionAuthZLabelFromRelationship(input: { targetID: $targetID })
  }
`;

export const REMOVE_VC_RELATIONSHIP = gql`
  mutation removeVCAuthZLabelFromRelationship($targetID: ID!) {
    removeVCAuthZLabelFromRelationship(input: { targetID: $targetID })
  }
`;
