import { Badge, Button, Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { VisibilityOutlined } from "@material-ui/icons";
import { useCanvasContext } from "contexts/canvasContext";
import React, { useEffect, useState } from "react";
import updateFilter from "./updateFilter";

interface FilterOrbitProps {
    isShowDashboard?: boolean;
    style?: any;
}

function FilterOrbit({ isShowDashboard, style }: FilterOrbitProps): React.ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const { d3Data, data, enters, setData } = useCanvasContext();

    const nItems = [
        { k: "Object", v: true, visibleOnFilter: ['dashboard'], orbitAlwaysVisible: true },
        { k: "Interface", v: true, visibleOnFilter: ['dashboard'] },
        { k: "Script", v: true, visibleOnFilter: ['dashboard'] },
        { k: "Custom Mutation", v: true, visibleOnFilter: ['dashboard'], orbitAlwaysVisible: true },
        { k: "Tag", v:  true, visibleOnFilter: ['dashboard'] },
        { k: "Node Relationship", v: true, visibleOnFilter: ['dashboard'], orbitAlwaysVisible: true },
        { k: "Enum", v: true, visibleOnFilter: ['dashboard'] },
        { k: "Path", v: true, visibleOnFilter: ['policy'] },
        { k: "PathCreate", v: true, visibleOnFilter: ['policy'] },
        { k: "PathRead", v: true, visibleOnFilter: ['policy'] },
        { k: "PathUpdate", v: true, visibleOnFilter: ['policy'] },
        { k: "PathDelete", v: true, visibleOnFilter: ['policy'] },
        { k: "Resource", v: true, visibleOnFilter: ['policy'] },
        { k: "Subject", v: true, visibleOnFilter: ['policy'] },
        // { k: "Policy", v: true, visibleOnFilter: ['policy'] },
        // { k: "Action", v: true, visibleOnFilter: ['policy'] },
        // { k: "Attribute", v: true, visibleOnFilter: ['policy'] },
        // { k: "Operator", v: true, visibleOnFilter: ['policy'] }
    ]

    const [items, setItems] = useState(nItems);

    useEffect(() => {
        if (d3Data.g)
        {
            const nItemsBeforeUpdated = items.map((i) => {
                i.v = true;
                return i;
            });

            setItems(nItemsBeforeUpdated);
            updateFilter(d3Data, data, nItemsBeforeUpdated, enters, setData);

            const nItemsUpdated = items.map((i) => {
                i.v = i.visibleOnFilter.includes(isShowDashboard ? 'dashboard' : 'policy') ? true : i.orbitAlwaysVisible ? true : false;
                return i;
            });

            setItems(nItemsUpdated);
            updateFilter(d3Data, data, nItemsUpdated, enters, setData);
        }
    }, [d3Data.g, isShowDashboard])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const selectItem = (item: { k: string; v: boolean }): void => {
        const nItems = items.map((i) => {
            if (i.k === item.k) {
                i.v = !i.v;
            }

            return i;
        });

        setItems(nItems);
        updateFilter(d3Data, data, nItems, enters, setData);
    };

    return (
        <div style={style}>
            <Button onClick={handleClick} style={{ padding: 3, border: '1px solid #BBB' }}>
                <VisibilityOutlined style={{ width: 32, height: 32 }} />
            </Button>

            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {
                    items
                    .filter(item => {
                        if (item.visibleOnFilter.includes(isShowDashboard ? 'dashboard' : 'policy') ? true : false) {
                            return item
                        }
                    })
                    .map((item) => {
                        let total = 0

                        switch (item.k) {
                            case "Object":
                                total = data?.objects?.length
                                break;
                            case "Interface":
                                total = data?.interfaces?.length
                                break;
                            case "Script":
                                total = data?.scripts?.length
                                break;
                            case "Custom Mutation":
                                total = data?.customMutations?.length
                                break;
                            case "Tag":
                                total = data?.tags?.length
                                break;
                            case "Node Relationship":
                                total = data?.nodeRelationships?.length
                                break;
                            case "Enum":
                                total = data?.enums?.length
                                break;
                        }

                        return (
                            <MenuItem
                                disabled={item.visibleOnFilter.includes('policy')}
                                key={item.k}
                                value={item.k}
                                onClick={(e) =>
                                {
                                    selectItem(item);
                                }}
                            >
                                <Checkbox checked={item.v} />

                                <Badge badgeContent={total} color="primary">
                                    <ListItemText primary={item.k} />
                                </Badge>
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </div>
    );
}

export default FilterOrbit;
