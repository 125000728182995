/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-lines */
/* eslint-disable flowtype/no-types-missing-file-annotation */
import { toastLoading, toastUpdate } from "@3edges/utils/dist/toastify";
import { isWebLocalhost } from "@3edges/utils/dist/utils";
import { useMutation } from "@apollo/client";
import { COLORS } from "assets/defaultVariables";
import { DefaultButton } from "components/ModalDataServer/styled";
import { SHUTDOWN_APIS } from "components/PanelServer/gql";
import React from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Cluster } from "utilsFn";
import { UPDATE_TO_RELEASED_APIS } from "./gql";
import { Body, Title } from "./styled";

function PanelDeploymentManagement (): React.ReactElement {
    const { t } = useTranslation();

    const [shutdownEachApis] = useMutation(SHUTDOWN_APIS);
    const [updateToReleasedEachApis] = useMutation(UPDATE_TO_RELEASED_APIS);

    const shutdownApis = async () => {
        const toastID = toastLoading()

        const domain = window.location.hostname
        const { errors }: any = await shutdownEachApis({ variables: { local: isWebLocalhost() ? Cluster.LOCALHOST : domain.includes('.qa.3edges.io') ?  Cluster.QA : Cluster.THREEEDGES } })

        if (errors) {
            for (const e of errors) {
                toastUpdate(toastID, t(`validations:${e.message}`), "error")
            }
            return;
        }

        toastUpdate(toastID, t("validations:success.done"), "success")
    }

    const updateToReleasedPods = async () => {
        const toastID = toastLoading()

        const domain = window.location.hostname
        const { errors }: any = await updateToReleasedEachApis({ variables: { local: isWebLocalhost() ? Cluster.LOCALHOST : domain.includes('.qa.3edges.io') ?  Cluster.QA : Cluster.THREEEDGES } })

        if (errors) {
            for (const e of errors) {
                toastUpdate(toastID, t(`validations:${e.message}`), "error")
            }
            return;
        }

        toastUpdate(toastID, t("validations:success.done"), "success")
    }

    return (
        <Body adminPage={false}>
            <Title>
                {t("deploymentManagement:header.title")}
            </Title>

            <div>
                <DefaultButton
                        style={{ width: 200, margin: 5 }}
                        onClick={() =>
                        {
                            void Swal.fire({
                                icon: "error",
                                title: t("deploymentManagement:shutdown.api.btn"),
                                showConfirmButton: true,
                                confirmButtonText: t("deploymentManagement:try.to.delete.yes"),
                                showCancelButton: true,
                                cancelButtonText: t("deploymentManagement:try.to.delete.no"),
                                confirmButtonColor: COLORS.GREEN,
                                cancelButtonColor: COLORS.RED
                            }).then((result) =>
                            {
                                if (result.isConfirmed) {
                                    shutdownApis();
                                }
                            });
                        }}
                    >
                    {t("root:btn.shutdown.apis.label")}
                </DefaultButton>

                <DefaultButton
                        style={{ width: 200, margin: 5 }}
                        onClick={() =>
                        {
                            void Swal.fire({
                                icon: "error",
                                title: t("deploymentManagement:update-to-released.pods.btn"),
                                showConfirmButton: true,
                                confirmButtonText: t("deploymentManagement:try.to.delete.yes"),
                                showCancelButton: true,
                                cancelButtonText: t("deploymentManagement:try.to.delete.no"),
                                confirmButtonColor: COLORS.GREEN,
                                cancelButtonColor: COLORS.RED
                            }).then((result) =>
                            {
                                if (result.isConfirmed) {
                                    updateToReleasedPods();
                                }
                            });
                        }}
                    >
                    {t("root:btn.update-to-released.pods.label")}
                </DefaultButton>
            </div>

        </Body>
    );
}

export default PanelDeploymentManagement;
