import { gql } from "@apollo/client";

export const UPDATE_PROFILE = gql`
  mutation updateProfile($userID: ID!, $params: updateProfileParamsInput!) {
      updateProfile(input: { userID: $userID, params: $params }) {
          _id
          company
          name
          address
          country
          mobilePhone
      }
  }
`;

export const UPDATE_EMAIL = gql`
  mutation updateEmail($userID: ID!, $params: updateEmailParamsInput!, $emailOptions: emailOptionsInput!) {
      updateEmail(input: { userID: $userID, params: $params, emailOptions: $emailOptions })
  }
`;

export const UPDATE_PWD = gql`
  mutation updatePWD($userID: ID!, $params: updatePWDParamsInput!) {
      updatePWD(input: { userID: $userID, params: $params })
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($userID: ID!, $params: deleteAccountParamsInput!) {
      deleteAccount(input: { userID: $userID, params: $params })
  }
`;

export const DELETE_ACCOUNT_ADMIN = gql`
  mutation adminDeleteUser($userID: ID!) {
      adminDeleteUser(input: { userID: $userID })
  }
`;
