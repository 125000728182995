import { iD3Selection } from "components/PrimGraphicalCanvas/types";
import { DataPoint } from "components/PrimGraphicalCanvas/Zoom/types";
import { DefaultTheme } from "styled-components";
import { IDropData } from "typedefs";

export const updateOrbit =
    (theme: Readonly<DefaultTheme>, dataTransfer: IDropData) => (selection: iD3Selection): void => {
        selection.attr("transform", (d: DataPoint["source"]) => `translate(${d.x} , ${d.y})`);

        selection.each((d: {
            _id: string;
            __typename: string;
            isSubject?: boolean;
            isAction?: boolean;
            isVC?: boolean;
            isHolder?: boolean;
            isIssuer?: boolean;
            isClaim?: boolean;
            isResource?: boolean;
            isPath?: boolean;
            isPathCreate?: boolean;
            isPathRead?: boolean;
            isPathUpdate?: boolean;
            isPathDelete?: boolean;
            simplePath?: { _id: string; relatedTo: string };
            simplePathCreate?: { _id: string; relatedTo: string };
            simplePathRead?: { _id: string; relatedTo: string };
            simplePathUpdate?: { _id: string; relatedTo: string };
            simplePathDelete?: { _id: string; relatedTo: string };
        }) => {
            // const selectedOrbit = select(`#orbitID_${d._id}`);
        })
};
