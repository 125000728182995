import { toastError, toastSuccess } from "@3edges/utils/dist/toastify";
import { useLazyQuery, useMutation } from "@apollo/client";
import { COLORS } from "assets/defaultVariables";
import { selectedUser } from "cache";
import ListPanel from "components/ListPanel";
import ModalTenant from "components/ModalTenant";
import { ActionButtonsContainer } from "components/PanelServer/styled";
import ShowMode from "components/ShowMode";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { GetAllTenants, GetAllTenants_niamTenants as NiamTenant, Role } from "types/operation-result-types";
import PanelCard from "ui-components/PanelCard";
import { DELETE_TENANT, GET_ALL_TENANTS } from "./gql";
import { Body, FlexList, TitleContainer } from "./styled";

function PanelTenant (): React.ReactElement {
    const { t } = useTranslation();
    const history = useHistory();
    const currentUser = selectedUser()

    const [getListTenants, { data, loading, refetch }] = useLazyQuery<GetAllTenants>(GET_ALL_TENANTS)

    const [deleteTenant] = useMutation(DELETE_TENANT);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editTenant, setEditTenant] = useState<NiamTenant | null>(null);
    const [isShowModeCard, setIsShowModeCard] = useState(true);

    useEffect(() => {
        getListTenants()
    }, []);

    useEffect(() => {
        if (!isModalOpen) {
            setEditTenant(null);
        }
    }, [isModalOpen]);

    if (loading) {
        return null;
    }

    const onEdit = (value: NiamTenant): void => {
        setEditTenant(value);
        setIsModalOpen(true);
    };

    const onDelete = (value: NiamTenant): void => {
        void Swal.fire({
            icon: "error",
            title: t("tenant:try.to.delete"),
            showConfirmButton: true,
            confirmButtonText: t("tenant:try.to.delete.yes"),
            showCancelButton: true,
            cancelButtonText: t("tenant:try.to.delete.no"),
            confirmButtonColor: COLORS.GREEN,
            cancelButtonColor: COLORS.RED
        }).then((result) => {
            if (result.isConfirmed) {
                void deleteTenant({
                    variables: {
                        tenantID: value._id
                    }
                }).then(({ errors }: any) => {
                    if (errors) {
                        for (const e of errors) {
                            toastError(t(`validations:${e.message}`));
                        }

                        return;
                    }

                    toastSuccess(t("tenant:message.after.deleted"));
                    void refetch();

                    const isAdmin = currentUser.roles.includes(Role.ADMIN);
                    const isSingleTenantRemained =  data?.niamTenants && data?.niamTenants.length === 1;

                    if (isAdmin && isSingleTenantRemained && data?.niamTenants[0]._id === value._id) {
                        // Need to remove the User's ADMIN role in localStorage after the ONLY Organization that they are in as ADMIN is deleted
                        const roles = currentUser.roles.filter(role => role !== Role.ADMIN);

                        selectedUser({
                            ...currentUser,
                            roles
                        } as any);
                    }
                });
            }
        });
    };

    const onChange = (value: boolean): void => {
        setIsShowModeCard(value);
    };

    const onSelect = (value: string): void => {
        history.push(`/${value}/servers`);
    };

    const showUsers = (value): void => {
        history.push(`/${value._id}/admin-page`);
    };

    return (
        <Body>
            <TitleContainer>
                {t("panel:header.tenants")}
            </TitleContainer>

            <ActionButtonsContainer>
                <ShowMode onChange={onChange} />
            </ActionButtonsContainer>

            <FlexList>
                {isShowModeCard
                    ? (
                        <>

                            <PanelCard
                                isAdd
                                text={t("panel:add.tenant.card")}
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            />

                            {data?.niamTenants.map((tn) => (
                                <PanelCard
                                    info={tn}
                                    icon="TenantIcon"
                                    text={tn.name}
                                    belongsTo={tn.administrators}
                                    tenantInfo={tn}
                                    key={`tenant-card-${tn._id}`}
                                    onClick={() => {
                                        onSelect(tn._id);
                                    }}
                                    onEdit={() => {
                                        onEdit(tn);
                                    }}
                                    onDelete={() => {
                                        onDelete(tn);
                                    }}
                                    onShowUsers={() => {
                                        showUsers(tn);
                                    }}
                                />
                            ))}
                        </>
                    )
                    : (
                        <ListPanel t={t} data={data.niamTenants} onEdit={onEdit} onDelete={onDelete} onSelect={onSelect} onShowUsers={showUsers} parentPage="tenant">

                            <PanelCard
                                isAdd
                                smallCard
                                text={t("panel:add.tenant.card")}
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                            />
                        </ListPanel>
                    )}

                <ModalTenant
                    onClose={() => {
                        void refetch()
                    }}
                    setIsModalOpen={setIsModalOpen}
                    tenantToEdit={editTenant}
                    isModalOpen={isModalOpen}
                    onSuccess={() =>
                    {
                        const asAdmin = currentUser.roles.includes(Role.ADMIN)
                        const roles = asAdmin ? [...currentUser.roles] : [...currentUser.roles, Role.ADMIN]

                        selectedUser({
                            ...currentUser,
                            roles
                        } as any);

                        toastSuccess(t("rightPanel:alert.save.success"));
                    }}
                />
            </FlexList>
        </Body>
    );
}

export default PanelTenant;
