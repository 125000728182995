/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-lines */
/* eslint-disable flowtype/no-types-missing-file-annotation */
import { toastError, toastLoading, toastSuccess, toastUpdate, toastWarning } from "@3edges/utils/dist/toastify";
import { isEmpty, isWebLocalhost, sleepFor } from "@3edges/utils/dist/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ApolloQueryResult, OperationVariables } from "@apollo/client/core";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { COLORS } from "assets/defaultVariables";
import { clearStorage, selectedUser } from "cache";
import { ButtonListFlex, ConfirmButton, DefaultButton } from "components/ModalDataServer/styled";
import { REDEPLOY_SPECIFIC_API, SEND_EMAIL_TO_CLIENTS, SHUTDOWN_APIS, SHUTDOWN_SPECIFIC_API, UPDATE_STARTSTOP } from "components/PanelServer/gql";
import { useCookie } from "contexts/cookieContext";
import { GET_LIST_OF_APIS_RUNNING, PRIM_CHECK_IF_USER_ALREADY_EXISTS } from "pages/CreateNewUser/gql";
import { RESET_YOUR_PWD } from "pages/Login/gql";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import
    {
        resetYourPWD as IResetPWD,
        adminGetUsers_adminGetUsers_users as User,
        adminGetUsers,
        adminGetUsersByTenant
    } from "types/operation-result-types";
import { StopApiServerIconStyled } from "ui-components/PanelCard/styled";
import { TextField } from "ui-components/styleds";
import { titleCase } from "utils";
import { Cluster, isSuperAdmin } from "utilsFn";
import { adminGetUsers_adminGetUsers_users as IAdminGetUsers, addNewUserShared_addNewUserShared as ITenantUser } from "../../types/operation-result-types";
import ModalChangeEmail from "./ModalChangeEmail";
import ModalChangePWD from "./ModalChangePwd";
import ModalDeleteAccount from "./ModalDeleteAccount";
import { DELETE_ACCOUNT, UPDATE_EMAIL, UPDATE_PROFILE, UPDATE_PWD } from "./gql";
import { Body, Title } from "./styled";

interface IPanelProfile {
    adminPage?: boolean;
    user?: IAdminGetUsers | ITenantUser;
    refetchUsers?: (
        variables?: Partial<OperationVariables>
    ) => Promise<ApolloQueryResult<adminGetUsers & adminGetUsersByTenant>>;
    onModalCancel?: () => void;
}

function PanelProfile ({
    adminPage,
    user,
    refetchUsers,
    onModalCancel
}: IPanelProfile): React.ReactElement {
    const { t } = useTranslation();
    const { removeAllCookies } = useCookie();
    const history = useHistory();

    const domain = window.location.hostname
    const currentUser = adminPage
        ? user
        : { ...selectedUser() };

    const [name, setName] = useState<string>(currentUser.name || "");
    const [email, setEmail] = useState<string>(currentUser.email || "");
    const [company, setCompany] = useState<string>(currentUser.company || "");
    const [address, setAddress] = useState(currentUser.address || "");
    const [country, setCountry] = useState(currentUser.country || "");
    const [mobilePhone, setMobilePhone] = useState(currentUser.mobilePhone || "");
    const superAdmin = isSuperAdmin();

    const [isModalOpenEmail, setIsModalOpenEmail] = useState(false);
    const [tmpEmail, setTmpEmail] = useState("");

    const [isModalOpenPWD, setIsModalOpenPWD] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const [isModalOpenAccount, setIsModalOpenAccount] = useState(false);
    const [emailAccount, setEmailAccount] = useState("");
    const [passwordAccount, setPasswordAccount] = useState("");

    const [updateProfile, { loading: waitProfile, called }] = useMutation<{ updateProfile: any }>(UPDATE_PROFILE);
    const [updateEmail, { loading: waitEmail }] = useMutation<{ updateEmail: boolean }>(UPDATE_EMAIL);
    const [updatePWD, { loading: waitPWD }] = useMutation(UPDATE_PWD);
    const [deleteAccount, { loading: waitDelete }] = useMutation(DELETE_ACCOUNT);
    const [checkEmail] = useLazyQuery(PRIM_CHECK_IF_USER_ALREADY_EXISTS);
    const [getListOfApisRunning, { data: dataApisRunning, loading: loadingApisRunning }] = useLazyQuery(GET_LIST_OF_APIS_RUNNING);
    const [resetYourPWD] = useMutation<IResetPWD>(RESET_YOUR_PWD);

    const [shutdownEachApis] = useMutation(SHUTDOWN_APIS);
    const [redeploySpecificWorkload] = useMutation(REDEPLOY_SPECIFIC_API);
    const [shutdownSpecificWorkload] = useMutation(SHUTDOWN_SPECIFIC_API);
    const [sendEmailToClients] = useLazyQuery(SEND_EMAIL_TO_CLIENTS);

    const [updateStartStop] = useMutation(UPDATE_STARTSTOP);

    const shutdownApis = async () => {
        const toastID = toastLoading()
        const { errors }: any = await shutdownEachApis({ variables: { local: isWebLocalhost() ? Cluster.LOCALHOST : domain.includes('.qa.3edges.io') ? Cluster.QA : Cluster.THREEEDGES } })

        if (errors) {
            for (const e of errors) {
                toastUpdate(toastID, t(`validations:${e.message}`), "error")
            }
            return;
        }

        toastUpdate(toastID, t("validations:success.done"), "success")
    }

    const redeploySpecificPod = async (clientNameGCP) => {
        const toastID = toastLoading()

        const { data } = await redeploySpecificWorkload({
            variables: {
                local: isWebLocalhost() ? Cluster.LOCALHOST : domain.includes('.qa.3edges.io') ?  Cluster.QA : Cluster.THREEEDGES,
                clientNameGCP
            }
        })

        if (data.reDeploySpecificApi.status) {
            toastUpdate(toastID, t("validations:success.done"), "success")
        } else {
            toastUpdate(toastID, t("validations:success.error"), "error")
        }
    }

    const shutdownSpecificPod = async (clientNameGCP) => {
        const toastID = toastLoading()

        const { data } = await shutdownSpecificWorkload({
            variables: {
                local: isWebLocalhost() ? Cluster.LOCALHOST : domain.includes('.qa.3edges.io') ? Cluster.QA : Cluster.THREEEDGES,
                clientNameGCP
            }
        })

        if (data.shutdownSpecificApi.status) {
            toastUpdate(toastID, t("validations:success.done"), "success")
        } else {
            toastUpdate(toastID, t("validations:success.error"), "error")
        }
    }

    const sendEmail = async () =>
    {
        const toastID = toastLoading()

        const { errors }: any = await sendEmailToClients({ variables: { local: isWebLocalhost() ? Cluster.LOCALHOST : domain.includes('.qa.3edges.io') ? Cluster.QA : Cluster.THREEEDGES, } })

        if (errors) {
            for (const e of errors) {
                toastUpdate(toastID, t(`validations:${e.message}`), "error")
            }
            return;
        }

        toastUpdate(toastID, t("validations:success.done"), "success")
    }

    const onConfirm = (): void => {
        updateProfile({
            variables: {
                userID: currentUser._id,
                params: {
                    name,
                    address,
                    company,
                    country,
                    mobilePhone
                }
            }
        })
            .then(({ data, errors }: any) => {
                if (errors) {
                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }
                    return;
                }

                if (adminPage) {
                    void refetchUsers();
                }

                if (data.updateProfile._id === selectedUser()._id) {
                    selectedUser({
                        ...currentUser,
                        ...data.updateProfile
                    } as any);

                    // file deepcode ignore DOMXSS: We agreed about it
                    document.querySelector("#username").innerHTML = titleCase(data.updateProfile.name || currentUser.email);
                }
                toastSuccess(t("validations:saved.success"));
            })
            .catch(() => toastError(t("validations:out.of.service")));
    };

    const onConfirmEmail = (): void => {
        if (isEmpty(tmpEmail)) {
            toastWarning(t("profile:tmpEmail.empty"));
            return;
        }

        void checkEmail({
            variables: {
                email: tmpEmail
            }
        })
            .then(({ data }) => {
                if (data.checkIfUserAlreadyExists.isVerified) {
                    toastWarning(t("createNewUser:email.already.exists"));
                    return;
                }

                updateEmail({
                    variables: {
                        userID: currentUser._id,
                        params: {
                            tmpEmail
                        },
                        emailOptions: {
                            subject: t("profile:emailOptions.subject"),
                            html: t("profile:emailOptions.html"),
                            text: t("profile:emailOptions.text")
                        }
                    }
                })
                    .then(({ data: res, errors }: any) => {
                        if (errors) {
                            for (const e of errors) {
                                toastError(t(`validations:${e.message}`));
                            }
                            return;
                        }

                        if (res.updateEmail) {
                            toastSuccess(t("profile:email.changed.success"));
                            setIsModalOpenEmail(false);
                        } else {
                            toastWarning(t("profile:email.changed.fail"));
                        }
                    })
                    .catch(() => toastError(t("validations:out.of.service")));
            })
            .catch(() => toastError(t("validations:out.of.service")));
    };

    const onConfirmPWD = (): void => {
        /*
        * Social Authentication does not create a password,
        * so when adding current password to set new password, check if it is social auth.
        * You can check whether it is social auth using:
        * getStorage<any>(REACT_ENV.REACT_APP_SOCIAL_PROVIDER_LOCAL_STORAGE_NAME);
        */

        if (isEmpty(newPassword)) {
            toastWarning(t("profile:password.empty"));
            return;
        }

        if (newPassword !== confirmNewPassword || isEmpty(newPassword)) {
            toastWarning(t("profile:newPassword.different.confirmNewPassword"));
            return;
        }

        updatePWD({
            variables: {
                userID: currentUser._id,
                params: {
                    newPassword
                }
            }
        })
            .then(({ errors }: any) => {
                if (errors) {
                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }
                    return;
                }

                toastSuccess(t("validations:saved.success"));
                setIsModalOpenPWD(false);
            })
            .catch(() => toastError(t("validations:out.of.service")));
    };

    const onConfirmDeleteAccount = (): void => {
        if (isEmpty(emailAccount) || isEmpty(passwordAccount)) {
            toastWarning(t("profile:newPassword.different.confirmNewPassword"));
            return;
        }

        deleteAccount({
            variables: {
                userID: currentUser._id,
                params: { email: emailAccount, password: passwordAccount }
            }
        })
            .then(({ errors }: any) => {
                if (errors) {
                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }
                    return;
                }

                toastSuccess(t("profile:message.after.deleted"));

                removeAllCookies()
                clearStorage();
                history.push("/login")
            })
            .catch(() => toastError(t("validations:out.of.service")));
    };

    useEffect(() => {
        getListOfApisRunning({ variables: { local: isWebLocalhost() ? Cluster.LOCALHOST : domain.includes('.qa.3edges.io') ?  Cluster.QA : Cluster.THREEEDGES } })
    }, []);

    useEffect(() => {
        if (called) {
            refetchUsers && void refetchUsers();
        }
    }, [called]);

    const stopServer = async (apiName) => {
        const toastID = toastLoading()
        updateStartStop({ variables: { serverID: apiName, start: false } })

        await sleepFor(5)
        toastUpdate(toastID, "Stopped", "success")
        getListOfApisRunning({ variables: { local: isWebLocalhost() ? Cluster.LOCALHOST : domain.includes('.qa.3edges.io') ?  Cluster.QA : Cluster.THREEEDGES } })
    }

    return (
        <Body adminPage={adminPage}>
            <Title>
                {t("profile:header.title")}
            </Title>

            <TextField
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value);
                }}
                label={t("profile:email.label")}
                disabled
            />

            <TextField
                value={name}
                autoFocus
                onChange={(e) => {
                    setName(e.target.value);
                }}
                label={t("profile:name.label")}
            />

            <TextField
                value={company}
                onChange={(e) => {
                    setCompany(e.target.value);
                }}
                label={t("profile:company.label")}
            />

            <TextField
                value={address}
                onChange={(e) => {
                    setAddress(e.target.value);
                }}
                label={t("profile:address.label")}
            />

            <TextField
                value={country}
                onChange={(e) => {
                    setCountry(e.target.value);
                }}
                label={t("profile:country.label")}
            />

            <TextField
                value={mobilePhone}
                onChange={(e) => {
                    setMobilePhone(e.target.value);
                }}
                label={t("profile:mobilePhone.label")}
            />

            <ButtonListFlex>
                <div className="additional-btns">
                    {!adminPage && (
                        <DefaultButton
                            onClick={() => {
                                setIsModalOpenEmail(true);
                            }}
                        >
                            {adminPage
                                ? t("root:btn.adminChange.email.label")
                                : t("root:btn.change.email.label")}
                        </DefaultButton>
                    )}

                    <DefaultButton
                        onClick={() => {
                            if (adminPage) {
                                resetYourPWD({
                                    variables: {
                                        email: (user as User).email,
                                        emailOptions: {
                                            subject: t("auth:emailOptions.subject"),
                                            html: t("auth:emailOptions.html"),
                                            text: t("auth:emailOptions.text")
                                        }
                                    }
                                })
                                    .then(({ data, errors }: any) => {
                                        if (errors) {
                                            for (const e of errors) {
                                                toastError(t(`validations:${e.message}`));
                                            }

                                            return;
                                        }

                                        if (data.resetYourPWD) {
                                            toastSuccess(t("auth:resetAdmin.pwd.success"));
                                            onModalCancel();
                                        } else {
                                            toastWarning(t("auth:reset.pwd.fail"));
                                        }
                                    })
                                    .catch(() => toastError(t("validations:out.of.service")));
                            } else {
                                setIsModalOpenPWD(true);
                            }
                        }}
                    >
                        {adminPage
                            ? t("root:btn.AdminChange.password.label")
                            : t("root:btn.change.password.label")}
                    </DefaultButton>

                    {!adminPage && (
                        <DefaultButton
                            color="default"
                            onClick={() => {
                                void Swal.fire({
                                    icon: "error",
                                    title: adminPage
                                        ? t("profile:AdminTry.to.delete")
                                        : t("profile:try.to.delete"),
                                    showConfirmButton: true,
                                    confirmButtonText: t("profile:try.to.delete.yes"),
                                    showCancelButton: true,
                                    cancelButtonText: t("profile:try.to.delete.no"),
                                    confirmButtonColor: COLORS.GREEN,
                                    cancelButtonColor: COLORS.RED
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        setIsModalOpenAccount(true);
                                    }
                                });
                            }}
                        >
                            {t("root:btn.delete.account.label")}
                        </DefaultButton>
                    )}
                </div>

                {!adminPage && (
                    <ConfirmButton
                        onClick={() => {
                            onModalCancel();
                        }}
                    >
                        {t("root:btn.modal.cancel")}
                    </ConfirmButton>
                )}

                <ConfirmButton onClick={onConfirm} disabled={waitProfile}>
                    {t("root:btn.save.label")}
                </ConfirmButton>
            </ButtonListFlex>

            {superAdmin && !adminPage && (
                <Accordion style={{ marginTop: 20 }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>
                            {t("root:extra.actions.title")}
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography component="div">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>

                                <div>
                                    <DefaultButton
                                        style={{ width: 200, margin: 5 }}
                                        onClick={() => {
                                            void Swal.fire({
                                                icon: "error",
                                                title: t("profile:send.email"),
                                                showConfirmButton: true,
                                                confirmButtonText: t("profile:try.to.delete.yes"),
                                                showCancelButton: true,
                                                cancelButtonText: t("profile:try.to.delete.no"),
                                                confirmButtonColor: COLORS.GREEN,
                                                cancelButtonColor: COLORS.RED
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    sendEmail();
                                                }
                                            });
                                        }}
                                    >
                                        {t("root:btn.send.email.to.clients")}
                                    </DefaultButton>
                                </div>

                                <div>
                                    <DefaultButton
                                    style={{ width: 200, margin: 5 }}
                                    onClick={() =>
                                    {
                                        void Swal.fire({
                                            icon: "error",
                                            title: t("profile:shutdown.api.btn"),
                                            showConfirmButton: true,
                                            confirmButtonText: t("profile:try.to.delete.yes"),
                                            showCancelButton: true,
                                            cancelButtonText: t("profile:try.to.delete.no"),
                                            confirmButtonColor: COLORS.GREEN,
                                            cancelButtonColor: COLORS.RED
                                        }).then((result) =>
                                        {
                                            if (result.isConfirmed) {
                                                shutdownApis();
                                            }
                                        });
                                    }}
                                >
                                        {t("root:btn.shutdown.apis.label")}
                                    </DefaultButton>

                                    <DefaultButton
                                    style={{ width: 200, margin: 5 }}
                                    onClick={() => {
                                        Swal.fire({
                                            icon: "info",
                                            input: 'text',
                                            text: "Paste here the ServerID",
                                            showConfirmButton: true,
                                            confirmButtonText: "Apply",
                                            showCancelButton: true,
                                            cancelButtonText: "Cancel",
                                            confirmButtonColor: COLORS.GREEN,
                                            cancelButtonColor: COLORS.RED
                                        }).then((result) => {
                                            if (result.isConfirmed && result.value) {
                                                shutdownSpecificPod(result.value);
                                            }
                                        });
                                    }}
                                >
                                        {t("root:btn.shutdown.specific.apis.label")}
                                    </DefaultButton>

                                </div>

                                <div>
                                    <DefaultButton
                                    style={{ width: 200, margin: 5 }}
                                    onClick={() => {
                                        Swal.fire({
                                            icon: "info",
                                            input: 'text',
                                            text: "Paste here the ServerID",
                                            showConfirmButton: true,
                                            confirmButtonText: "Apply",
                                            showCancelButton: true,
                                            cancelButtonText: "Cancel",
                                            confirmButtonColor: COLORS.GREEN,
                                            cancelButtonColor: COLORS.RED
                                        }).then((result) => {
                                            if (result.isConfirmed && result.value) {
                                                redeploySpecificPod(result.value);
                                            }
                                        });
                                    }}
                                >
                                        {t("root:btn.redeploy.one.pod.label")}
                                    </DefaultButton>
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            )}

            {superAdmin && (
                <div style={{ margin: 10 }}>
                    {loadingApisRunning && <div>Loading apps...</div>}

                    {!loadingApisRunning && dataApisRunning && dataApisRunning.getListOfApisRunning && (
                        <table style={{ border: '1px solid', margin: 10, padding: 10 }}>
                            <thead>
                                <tr>
                                    <th><u>Tenant Name</u></th>
                                    <th><u>ClientNameGCP</u></th>
                                    <th><u>Action</u></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataApisRunning.getListOfApisRunning.map((apiInfo, i) => {
                                    return (
                                        <tr key={`apiInfo-${i}`}>
                                            <td>
                                                <Link to={apiInfo.tenantID ? `/${apiInfo.tenantID}/servers` : `/servers`}>
                                                    {apiInfo.tenantID ? apiInfo.tenantName : "Standalone"}
                                                </Link>
                                            </td>
                                            <td>
                                                <Link to={`/ds/${apiInfo.serverID}`}>
                                                    {apiInfo.clientNameGCP}
                                                </Link>
                                            </td>
                                            <td align='center'>
                                                <StopApiServerIconStyled $smallCard>
                                                    <span onClick={() => stopServer(apiInfo.serverID)} title={t("dataserver:ModalDataServer.stopBtn")} className="material-icons-outlined">stop_circle</span>
                                                </StopApiServerIconStyled>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            )}

            <ModalChangeEmail
                isModalOpenEmail={isModalOpenEmail}
                tmpEmail={tmpEmail}
                setTmpEmail={setTmpEmail}
                waitEmail={waitEmail}
                onSave={onConfirmEmail}
                onClose={() => {
                    setIsModalOpenEmail(false);
                }}
            />

            <ModalChangePWD
                adminPage={adminPage}
                isModalOpenPWD={isModalOpenPWD}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                confirmNewPassword={confirmNewPassword}
                setConfirmNewPassword={setConfirmNewPassword}
                waitPWD={waitPWD}
                onSave={onConfirmPWD}
                onClose={() => {
                    setIsModalOpenPWD(false);
                }}
            />

            <ModalDeleteAccount
                isModalOpenAccount={isModalOpenAccount}
                emailAccount={emailAccount}
                setEmailAccount={setEmailAccount}
                passwordAccount={passwordAccount}
                setPasswordAccount={setPasswordAccount}
                waitDelete={waitDelete}
                onSave={onConfirmDeleteAccount}
                onClose={() => {
                    setIsModalOpenAccount(false);
                }}
            />
        </Body>
    );
}

export default PanelProfile;
