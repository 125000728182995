/* eslint-disable jsx-a11y/no-autofocus */
import { toastError } from "@3edges/utils/dist/toastify";
import { isEmpty } from "@3edges/utils/dist/utils";
import { useMutation } from "@apollo/client";
import { TextFieldProps } from "@material-ui/core";
import { ButtonListFlex, ConfirmButton } from "components/ModalDataServer/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "ui-components/modal";
import { TextField } from "ui-components/styleds";
import { ADD_NEW_PRIM_TENANT, UPDATE_PRIM_TENANT } from "../PanelTenant/gql";

interface ModalTenantProps {
    isModalOpen: boolean;
    tenantToEdit: {
        _id: string;
        name: string;
    };
    setIsModalOpen: (state: boolean) => void;
    onSuccess: () => void;
    onClose: () => void;
}

function ModalTenant ({ isModalOpen, tenantToEdit, setIsModalOpen, onSuccess, onClose }: ModalTenantProps): React.ReactElement {
    const { t } = useTranslation();
    const [name, setName] = useState("");

    const [addNewPrimTenant] = useMutation(ADD_NEW_PRIM_TENANT);
    const [updateNiamTenant] = useMutation(UPDATE_PRIM_TENANT);

    useEffect(() => {
        if (!isModalOpen) {
            setName("");
        } else if (tenantToEdit) {
            setName(tenantToEdit.name);
        }
    }, [isModalOpen, tenantToEdit]);

    const onConfirm = (): void => {
        if (tenantToEdit) {
            void updateNiamTenant({
                variables: {
                    tenantID: tenantToEdit._id,
                    name
                }
            }).then(({ errors }: any) => {
                if (errors) {
                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }

                    return;
                }

                onSuccess();
                onCloseModal();
            });
        } else {
            void addNewPrimTenant({
                variables: {
                    name
                }
            }).then(({ errors }: any) => {
                if (errors) {
                    for (const e of errors) {
                        toastError(t(`validations:${e.message}`));
                    }

                    return;
                }

                onSuccess();
                onCloseModal();
            });
        }
    };

    const onCloseModal = (): void => {
        setIsModalOpen(false);
        onClose()
    };

    const onChange: TextFieldProps["onChange"] = (e) => {
        setName(e.target.value);
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={() => {
                setIsModalOpen(false);
                onClose()
            }}
            style={{ width: "600px" }}
        >
            <h1>
                {t("tenant:ModalTenant.title")}
            </h1>

            <TextField
                label={t("tenant:ModalTenant.name.label")}
                autoFocus
                data-cy="modalOrganizationPage_name"
                placeholder={t("tenant:ModalTenant.name.placeholder")}
                value={name}
                onChange={onChange}
            />

            <ButtonListFlex>
                <ConfirmButton onClick={onConfirm} disabled={isEmpty(name)}>
                    Save
                </ConfirmButton>
            </ButtonListFlex>
        </Modal>
    );
}

export default ModalTenant;
