import { gql } from "@apollo/client";

export const UPDATE_TO_RELEASED_APIS = gql`
  mutation updateToReleasedEachApis($local: String!) {
    updateToReleasedEachApis(input: { local: $local})
  }
`

export const UPDATE_TO_RELEASED_SPECIFIC_API = gql`
  mutation updateToReleasedSpecificWorkload($local: String!, $clientNameGCP: String!) {
    updateToReleasedSpecificWorkload(input: { local: $local, clientNameGCP: $clientNameGCP}) {
      status
    }
  }
`
